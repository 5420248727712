import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api'; // TODO: review usage of primeng just for this

export enum Severity {
  Info = 'info',
  Warning = 'warn',
  Error = 'error',
  Success = 'success',
}

@Injectable({
  providedIn: 'root',
})
export class AppMessageService {
  constructor(private messageService: MessageService) {}

  public success(summary: string, detail: string) {
    this.messageService.add({
      severity: Severity.Success,
      summary: summary,
      detail: detail,
    });
  }

  public error(summary: string, detail: string) {
    this.messageService.add({
      severity: Severity.Error,
      summary: summary,
      detail: detail,
    });
  }

  public warn(summary: string, detail: string) {
    this.messageService.add({
      severity: Severity.Warning,
      summary: summary,
      detail: detail,
    });
  }

  public info(summary: string, detail: string) {
    this.messageService.add({
      severity: Severity.Info,
      summary: summary,
      detail: detail,
    });
  }

  public message(severity: Severity, summary: string, detail: string) {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: detail,
    });
  }
}
