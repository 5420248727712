import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { ReportingService, TestRunFilteringData } from 'src/app/services/reporting.service';
import { ScaniaDropdownOption } from '../scania/scania-dropdown/scania-dropdown-option';

@Component({
  selector: 'tl-reports-filter-wrapper',
  templateUrl: './tl-reports-filter-wrapper.html',
  styleUrls: ['./tl-reports-filter-wrapper.scss']
})

export class TlReportsFilterWrapper implements OnInit {

  // @Output() onReportFormatChange = new EventEmitter<string>();
  @Input() customType?: string;
  @Input() exportFilesEnabled?: boolean = false;
  @Output() onReportTypeChange = new EventEmitter<string>();
  @Output() onGenerate = new EventEmitter<string>();
  @Output() onExportFiles = new EventEmitter<string>();


  reportOptions: ScaniaDropdownOption[] = [
    { label: 'Simple', value: 'simple' }, 
    { label: 'Benchmark', value: 'benchmark' }, 
    { label: 'Custom', value: 'custom' }
  ]
  formatOptions: ScaniaDropdownOption[] = [
    { label: 'TXT', value: 'txt' }, 
    { label: 'HTML', value: 'html' }, 
    { label: 'PDF', value: 'pdf' }, 
    { label: 'DOCX', value: 'docx' },
    { label: 'CSS', value: 'css' },
    { label: 'RTF', value: 'rtf' },
    { label: 'XML', value: 'xml' },
    { label: 'PDFA', value: 'pdfa' },
    { label: 'TX', value: 'tx' },
    { label: 'DOC', value: 'doc' }, 
    { label: 'XLS', value: 'xls' }, 
  ]

  selectedReport: string = 'docx'
  selectedFormat: string
  
  constructor(private reportingService: ReportingService) {
  }

  ngOnInit() {    
    // to be implemented in Abstraction Layer?
    // this.getReportTemplates()
  }

  private getReportTemplates () {
    this.reportingService.getTemplates().subscribe((response) => {
      this.reportOptions = response.map<ScaniaDropdownOption>(x => {return {value: x.templateUid, label: x.name}})
      if (!this.customType) {
        this.reportOptions.unshift(
          { label: 'Simple', value: 'simple' }, 
          { label: 'Benchmark', value: 'benchmark' }
        )
      }
    })
  }

  onReportChange(event) {
    this.selectedReport = event.value
    this.onReportTypeChange.emit(this.selectedReport);
    // this.filterTestRuns()
  }

  // onFormatChange(event) {
  //   this.selectedFormat = event.value
  //   this.onReportFormatChange.emit(this.selectedFormat);
  //   // this.filterTestRuns()
  // }

  onGenerateClick() {
    this.onGenerate.emit(this.selectedReport)
  }

  onExportFilesClick() {
    this.onExportFiles.emit(this.selectedReport)
  }

  onFormattChange() {
    
  }

  private filterTestRuns () {
  //   let newFilteredTestRuns = []
  //   for (let testRun of this.testRuns) {
  //     let addToFiltered = true
  //     if (this.selectedBooking && !(testRun.booking && testRun.booking.id.toString() == this.selectedBooking)) addToFiltered = false
  //     if (this.selectedTestSuite && !(testRun.mainTestSuiteUid == this.selectedTestSuite)) addToFiltered = false
  //     if (this.selectedVehicle && !(testRun.vehicleName == this.selectedVehicle)) addToFiltered = false
  //     if (addToFiltered) newFilteredTestRuns.push(testRun)
  //   }
  //   this.filteredTestRuns = newFilteredTestRuns
  }
}