import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { BaseUserComponent } from './components/base-component';
import { TestRunView } from './models/test-run';
import { AppMessageService } from './services/message.service';
import { GlobalState } from './store/global/global.state';
import { Observable, combineLatest } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { AuthService } from './services/auth.service';

declare const TXTextControl: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

// Extends base user component, basic component with user 
export class AppComponent extends BaseUserComponent {
  title = 'reports-client';

  public txtContent: string
  public txtContentType: string = "docx"
  public reportType: string
  public testRunUid: string = ""
  public testRuns: TestRunView[]
  public loading: boolean = true
  public showTestRuns: boolean = true

  public isLoggedIn = false

  // private destroyRef = inject(DestroyRef)
  params$: Observable<ParamMap> = this.route.queryParamMap
  navigationEnded$ = this.router.events.pipe(filter(e => e instanceof NavigationEnd));

  constructor(
    messageService: AppMessageService,
    store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {
    super(store, messageService);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit()

    this.subscribe(GlobalState.getLoadingNumber, (loadingNumber) => {
      this.loading = loadingNumber > 0
    });

    combineLatest({
      params: this.params$,
      navigationEnded: this.navigationEnded$,
      userData: this.authService.userData$
    }).pipe(
      tap(source => {
        if (!!source.params.get('sessionId')) {
          const sessionId = source.params.get('sessionId') ?? ""
          const username = source.params.get('username') ?? ""
          const company = source.params.get('company') ?? ""
          this.authService.updateSession(sessionId, username, company)
        }
      }),
      switchMap(() => {
        return this.authService.login()
      })
    ).subscribe()

    this.authService.userData$.subscribe((value) => {
      this.isLoggedIn = value.isLoggedIn
    })
  }
}
