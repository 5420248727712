<h1 style="display: inline-block; margin-left: 30px;">{{currentCollection.reportName}}</h1>

<h1 *ngIf="collections.length === 0" style="display: inline-block; margin-left: 30px;">Collection {{collectionName}} is empty.</h1>

<div *ngIf="collections.length > 0" style="display: flex;">
  <div style="display: grid; flex: 2; grid-template-columns: repeat(auto-fill,minmax(250px, 1fr));">
    <div *ngFor="let chart of currentCollection.charts; let i = index"  style="margin: 20px; height: 200px; padding: 0px; position: relative;">
      <div  [ngStyle]="{'position': 'absolute', 'width': '20px', 'height': '20px', 'borderRadius': '10px', 'background': chart.bgColor}" ></div>
      <div [ngClass]="'chartWrapper'" [ngStyle]="{'position': 'absolute', top: '30px', left: '0', 'height': '200px'}"  attr.data-vehicle="{{chart.vehicleName}}">
        <ngx-charts-bar-vertical
          [view]="view"
          [scheme]="colorScheme"
          [schemeType]="schemeType"
          [results]="chart.data"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [legend]="showLegend"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="chart.vehicleName"
          [yAxisLabel]="yAxisLabel"
          [rotateXAxisTicks]="rotateXAxisTicks"
          (select)="onSelect($event)">
        </ngx-charts-bar-vertical>
      </div>
    </div>
  </div>
  <div style="margin: 10px; display: flex; flex: 1; height: 400px;">
    <ngx-charts-line-chart
      [view]="view"
      [showXAxisLabel]="false"
      [showYAxisLabel]="false"
      [xAxis]="true"
      [yAxis]="true"
      xAxisLabel="Date"
      yAxisLabel="Distance"
      [results]="currentCollection.summaryChart"
      [rotateXAxisTicks]="rotateXAxisTicks"
      >
    </ngx-charts-line-chart>
  </div>
</div>

