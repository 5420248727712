import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { ReportingService, TestRunFilteringData } from 'src/app/services/reporting.service';
import { ScaniaDropdownOption, ScaniaDropDownOptionNone } from '../scania/scania-dropdown/scania-dropdown-option';
import * as moment from 'moment';
import { TestRunView } from 'src/app/models/test-run';
import { ReportingGraphQLService } from 'src/app/services/graphql/reporting-graphql.service';


@Component({
  selector: 'tl-testruns-filter-wrapper',
  templateUrl: './tl-testruns-filter-wrapper.html',
  styleUrls: ['./tl-testruns-filter-wrapper.scss']
})

export class TlTestrunsFilterWrapper implements OnInit {

  @Input() preSelectedTestRunUids?: string[] = []

  @Output() onTestRunChange = new EventEmitter<string>();
  @Output() onSelectedTestRunsChanged = new EventEmitter<TestRunView[]>();

  dateFrom: Date = moment().subtract(7, 'days').toDate()
  dateTo: Date = new Date()
  filteringData: TestRunFilteringData = {
    vehicles: [],
    bookings: [],
    testSuites: [],
    tsps: [],
    properties: []
  }
  vehicleOptions: ScaniaDropdownOption[] = []
  testSuiteOptions: ScaniaDropdownOption[] = []
  tspOptions: ScaniaDropdownOption[] = []
  bookingOptions: ScaniaDropdownOption[] = []
  propertyOptions: ScaniaDropdownOption[] = []

  selectedVehicle: string
  selectedBooking: string
  selectedTestSuite: string
  selectedProperty: string
  selectedTsp: string

  testRuns: TestRunView[] = []
  filteredTestRuns: TestRunView[] = []

  testRunsSortingState: any = {
    vehicleName: true,
    mainTestSuiteDisplayName: false,
    executionTime: false
  }

  moment: any = moment;

  constructor(
    private reportingService: ReportingService,
    private reportingGraphQLService: ReportingGraphQLService
  ) {}

  ngOnInit() {
    this.getTestRuns()
  }

  private getTestRuns() {

    this.reportingGraphQLService.getTestRuns(this.dateFrom, this.dateTo).subscribe((response) => {
      this.testRuns = response.map<TestRunView>(x => { return { ...x, checked: false } })
      this.filteringData = this.reportingService.parseTestRuns(response)
      this.vehicleOptions = this.filteringData.vehicles.map<ScaniaDropdownOption>(x => { return { value: x.name, label: x.name } }).sort((a, b) => a.label > b.label ? 1 : -1)
      this.testSuiteOptions = this.filteringData.testSuites.map<ScaniaDropdownOption>(x => { return { value: x.uid, label: x.displayName } }).sort((a, b) => a.label > b.label ? 1 : -1)
      this.tspOptions = this.filteringData.tsps.map<ScaniaDropdownOption>(x => { return { value: x.uid, label: x.displayName } }).sort((a, b) => a.label > b.label ? 1 : -1)
      this.bookingOptions = this.filteringData.bookings.map<ScaniaDropdownOption>(x => { return { value: x.ID.toString(), label: `${x.VehicleName} - ${x.FromDate}` } }).sort((a, b) => a.label > b.label ? 1 : -1)

      this.vehicleOptions.unshift(ScaniaDropDownOptionNone)
      this.testSuiteOptions.unshift(ScaniaDropDownOptionNone)
      this.bookingOptions.unshift(ScaniaDropDownOptionNone)
      this.tspOptions.unshift(ScaniaDropDownOptionNone)


      var preSelected = this.processPreselected()
      if (preSelected.length > 0) {
        this.filteredTestRuns = preSelected
      } else {
        this.filterTestRuns()
      }
      this.sortTestRuns('vehicleName')
      // console.log(this.filteringData)
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes)
    // this.preSelectedTestRunUids = changes.p ? changes.report.currentValue : this.report
  }

  onDateChange(event) {
    this.getTestRuns()
  }

  onVehicleChange(event) {
    this.selectedVehicle = event.value
    this.filterTestRuns()
  }

  onTestSuiteChange(event) {
    this.selectedTestSuite = event.value
    this.filterTestRuns()
  }

  onBookingChange(event) {
    this.selectedBooking = event.value
    this.filterTestRuns()
  }

  onPropertyChange(event) {
    this.selectedProperty = event.value
    this.filterTestRuns()
  }

  onTspChange(event) {
    this.selectedTsp = event.value
    this.filterTestRuns()
  }

  onTestRunClick(testRunUid) {
    this.onTestRunChange.emit(testRunUid);
  }

  onTestRunCheck(testRun: TestRunView) {
    testRun.checked = !testRun.checked
    const selectedTestRuns = this.testRuns.filter(x => x.checked)
    this.onSelectedTestRunsChanged.emit(selectedTestRuns)
  }

  sortTestRuns(attribute: string) {
    // alert(attribute)
    let direction = this.testRunsSortingState[attribute] ? 1 : -1
    this.filteredTestRuns.sort((a, b) => {
      const aValue = a[attribute] ? a[attribute] : ""
      const bValue = b[attribute] ? b[attribute] : ""
      return aValue.localeCompare(bValue) * direction
    })
    this.testRunsSortingState[attribute] = !this.testRunsSortingState[attribute]
  }

  private processPreselected() {
    // check if preselected 
    for (let testRun of this.testRuns) {
      for (let selectedTrUid of this.preSelectedTestRunUids) {
        if (selectedTrUid == testRun.TestRunUid) {
          testRun.checked = true
        }
      }
    }
    const selectedTestRuns = this.testRuns.filter(x => x.checked)
    this.onSelectedTestRunsChanged.emit(selectedTestRuns)
    return selectedTestRuns
  }

  private filterTestRuns() {
    let newFilteredTestRuns = []
    for (let testRun of this.testRuns) {
      let addToFiltered = true
      if (this.selectedBooking && this.selectedBooking != ScaniaDropDownOptionNone.value && !(testRun.Booking && testRun.Booking.ID.toString() == this.selectedBooking)) addToFiltered = false
      // if (this.selectedTestSuite && this.selectedTestSuite != ScaniaDropDownOptionNone.value && !(testRun.MainTestSuiteUid == this.selectedTestSuite)) addToFiltered = false
      // if (this.selectedTsp && this.selectedTsp != ScaniaDropDownOptionNone.value && !(testRun.TspUid == this.selectedTsp)) addToFiltered = false
      if (this.selectedVehicle && this.selectedVehicle != ScaniaDropDownOptionNone.value && !(testRun.VehicleName == this.selectedVehicle)) addToFiltered = false
      if (addToFiltered) newFilteredTestRuns.push(testRun)
    }
    this.filteredTestRuns = newFilteredTestRuns
  }
}