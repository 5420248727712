import { Component, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Store } from '@ngxs/store';
import { NgxChartsModule, ScaleType } from '@swimlane/ngx-charts';
import * as moment from 'moment';
import { BaseUserComponent } from 'src/app/components/base-component';
import { SevenDayMlogReportDTO, SevenDayReportDTO } from 'src/app/models/dto/seven-days';
import { AuthService } from 'src/app/services/auth.service';
import { AppMessageService } from 'src/app/services/message.service';
import { ReportingService } from 'src/app/services/reporting.service';
import { GlobalState } from 'src/app/store/global/global.state';

declare var $: any;

@Component({
  selector: 'seven-days-mlog-report',
  templateUrl: './seven-days-mlog-report.html',
  styleUrls: ['./seven-days-mlog-report.scss']
})
export class SevenDaysMLogReport extends BaseUserComponent {

  loading: boolean = false
  charts: any[] = []
  summaryChart: any[] = []
  reportName: string

  constructor(
    messageService: AppMessageService,    
    store: Store,
    private authService: AuthService,
    private reportingService: ReportingService,
    private route: ActivatedRoute
  ) {
    super(store, messageService);
  }

  ngOnInit(): void {
    super.ngOnInit()

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.reportName = params.get('reportName');
      if (this.reportName) {
        this.getSevenDayReportData(this.reportName)
      }
    });
    // if (!this.reportName) {
    //   this.reportName = "ehighway EDIT NAME"
    // }

    this.subscribe(GlobalState.getLoadingNumber, (loadingNumber) => {
      this.loading = loadingNumber > 0
    });

    $('.runtimeInfo').remove();

    let that = this

    $(window).on('resize', function() {
      const timeout = setTimeout(() => {
        $('.runtimeInfo').remove();
        $('.bar').each(function() {
          var position =  $( this ).position()
          var width = $(this).closest("g")[0].getBBox().width
          var tooltip = $(this).attr("aria-label")
          var xValue = tooltip.split(" ")[0]
          var vehicleName = $(this).closest(".chartWrapper").data("vehicle")
          let value = ""
          for (let chart of that.charts) {
            if (chart.vehicleName == vehicleName) {
              for (let point of chart.data) {
                if (xValue == point.name) {
                  value = point.value.toString()
                }
              }
            }
          }
          $("body").append(`<div class="runtimeInfo" style="position: absolute; text-align: center; font-size: 16px; width: ${width}px; left: ${position.left}px; top: ${position.top - 20}px;">${value}</div>`)
        });
        clearTimeout(timeout)
      }, 1000)
    });
  }

  public getSevenDayReportData (reportName: string) {
    
    this.reportingService.getSevenDayMLogReport(reportName).subscribe((response: SevenDayMlogReportDTO[]) => {
      console.log(response)
      this.charts = []
      // vehicle reports
      for (const data of response) {
        const vehicleName = data.vehicleName
        const chart = this.charts.filter(x => x.vehicleName == vehicleName)
        const newData = {
          name: moment(data.loggerStartDate).format("DD-MMM"),
          value: this.getMb(data.fileSizeInBytes),
        }
        if (!chart.length) {
          const newChart = {
            vehicleName: vehicleName,
            data: [newData]
          }          
          this.charts.push(newChart)
        } else {
          chart[0].data.push(newData)
        }
      }
      for (const chart of this.charts) {
        for (let dayOffset = 0; dayOffset < 7; dayOffset++) {
          const firstDate = moment().add(-12, "day");
          const curDate = firstDate.add(dayOffset, "day").format("DD-MMM")
          const dateExist = chart.data.findIndex((x) => x.name == curDate) != -1
          if (!dateExist) {
            chart.data.push({
              name: curDate,
              value: 0,
              runtime: 0
            })
          }
        }
        // chart.data.push({
        //   name: '26-Nov',
        //   value: 100
        // })
        chart.data.sort((a, b) => a.name > b.name ? 1 : -1)
        chart.barOffset = 100 / (chart.data.length) 
        chart.barWidth = 100 / (chart.data.length)
      }
      // summary report
      // for (const data of result.summaryChartData) {
      //   this.summaryChart[0].series.push({
      //     name: moment(data.date).format("YYYY-MM-DD"),
      //     value: data.distance
      //   })
      // }    
      // this.summaryChart[0].series.sort((a, b) => a.name > b.name ? 1 : -1)
    })
    setTimeout(function(){ window.dispatchEvent(new Event('resize')); }, 2000);
  }

  view: any = undefined // [700, 400];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Country';
  showYAxisLabel = false;
  yAxisLabel = 'Distance';
  rotateXAxisTicks = true;

  colorScheme =  'aqua'
  schemeType: ScaleType = ScaleType.Linear


  onSelect(event) {
    console.log(event);
  }

  private parseSize (sizeString: string): number {
    sizeString = sizeString.replace("MB", "")
    let size = 0
    try {
      size = parseFloat(sizeString)
    } catch (ex) {
      console.log(ex)
    }
    return size
  }

  
  private getMb (bytesNumber: number): number {
    return Math.round(bytesNumber/1024/1024)
  }
} 