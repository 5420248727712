<div class="tl-filter-wrapper">
  <div style="display: flex;">
    <div style="flex: 1; padding: 10px;">
      <div><label for="dateTo">Report type</label></div>
      <scania-dropdown
        [options]="reportOptions"
        initialValue="docx"
        (onChange)="onReportChange($event)"
      ></scania-dropdown>
    </div>
    <div style="flex: 1; padding: 10px; display: flex; align-items: center;">
      <button class="sdds-btn sdds-btn-secondary sdds-btn-sm" style="margin-top: 10px" (click)="onGenerateClick()"> Generate <span class='sdds-btn-icon' style="color: #0f3263;"><sdds-icon name="scania-circular_arrows"></sdds-icon></span></button>
      <button *ngIf="exportFilesEnabled" class="sdds-btn sdds-btn-secondary sdds-btn-sm" style="margin: 10px 0 0 10px" (click)="onExportFilesClick()"> Export attached files <span class='sdds-btn-icon' style="color: #0f3263;"><sdds-icon name="scania-download"></sdds-icon></span></button>
    </div>      
  </div>
</div>