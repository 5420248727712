<div class="tl-filter-wrapper">
  <div style="display: flex; padding: 10px;">
    <div style="flex: 1">
      <div><label for="dateFrom">From</label></div>
      <p-calendar [(ngModel)]="dateFrom" dateFormat="yy-mm-dd" inputId="dateFrom" (onSelect)="onDateChange($event)"></p-calendar>
    </div>
    <div style="flex: 1; padding-left: 20px;">
      <div><label for="dateTo">To</label></div>
      <p-calendar [(ngModel)]="dateTo" dateFormat="yy-mm-dd" inputId="dateTo" (onSelect)="onDateChange($event)"></p-calendar>
    </div>
  </div>
  <div style="display: flex;">
    <div style="flex: 1; padding: 10px;">
      <div><label for="dateTo">Vehicles</label></div>
      <scania-dropdown
        [options]="vehicleOptions"
        initialValue="opt-2"
        (onChange)="onVehicleChange($event)"
      ></scania-dropdown>
    </div>
    <div style="flex: 1; padding: 10px;">
      <div><label for="dateTo">Test suites</label></div>
      <scania-dropdown
        [options]="testSuiteOptions"
        initialValue="opt-2"
        (onChange)="onTestSuiteChange($event)"
      ></scania-dropdown>
    </div>    
  </div>
  <div style="display: flex;">
    <div style="flex: 1; padding: 10px;">
      <div><label for="dateTo">Bookings</label></div>
      <scania-dropdown
        [options]="bookingOptions"
        initialValue="opt-2"
        (onChange)="onBookingChange($event)"
      ></scania-dropdown>
    </div>
    <div style="flex: 1; padding: 10px;">
      <div><label for="dateTo">Test Specifications</label></div>
        <scania-dropdown
          [options]="tspOptions"
          initialValue="none"
          (onChange)="onTspChange($event)"
        ></scania-dropdown>
    </div>    
  </div>

  <div style="padding: 10px;">
    <table class="sdds-table">
      <thead>
           <tr>
              <th style="width: 30px; min-width: 30px;"></th>
              <th (click)="sortTestRuns('vehicleName')">Vehicle 
                <sdds-icon *ngIf="testRunsSortingState.vehicleName" name="scania-angle-down"></sdds-icon>
                <sdds-icon *ngIf="!testRunsSortingState.vehicleName" name="scania-angle-down" style="transform: rotate(180deg)"></sdds-icon>
              </th>
              <th (click)="sortTestRuns('mainTestSuiteDisplayName')">Test Suite / TSP
                <sdds-icon *ngIf="testRunsSortingState.mainTestSuiteDisplayName" name="scania-angle-down"></sdds-icon>
                <sdds-icon *ngIf="!testRunsSortingState.mainTestSuiteDisplayName" name="scania-angle-down" style="transform: rotate(180deg)"></sdds-icon>
              </th>
              <th (click)="sortTestRuns('executionTime')">Execution Time
                <sdds-icon *ngIf="testRunsSortingState.executionTime" name="scania-angle-down"></sdds-icon>
                <sdds-icon *ngIf="!testRunsSortingState.executionTime" name="scania-angle-down" style="transform: rotate(180deg)"></sdds-icon>
              </th>
            </tr>
       </thead>
       <tbody>
            <tr *ngFor="let testRun of filteredTestRuns; let i = index" (click)="onTestRunClick(testRun.TestRunUid)">
                <td style="width: 30px; min-width: 30px;">
                  <div class="sdds-checkbox-item">
                    <input class="sdds-form-input" type="checkbox" name="cb-example" id="cb-option-1" [checked]="testRun.checked" (change)="onTestRunCheck(testRun)">
                  </div>
                </td>
                <td>{{testRun.VehicleName}}</td> 
                <td>{{testRun.TSName}}</td>
                <td>{{moment(testRun.ExecutionTime).format('YYYY-MM-DD HH:mm:ss')}}</td>      
             </tr>         
        </tbody>
      </table>
  </div>
</div>