import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { ReportDTO } from 'src/app/models/dto/report';
import { ReportSaveDto } from 'src/app/models/dto/report-save';
import { BaseUserComponent } from '../../components/base-component';
import { AppMessageService } from '../../services/message.service';
import { ReportingService } from '../../services/reporting.service';
import { UserActions } from '../../store/users/users.actions';
import { AuthService } from 'src/app/services/auth.service';

declare const TXTextControl: any;

@Component({
  selector: 'testsuite-reports',
  templateUrl: './testsuite-reports.html',
  styleUrls: ['./testsuite-reports.scss']
})

// Extends base user component, basic component with user 
export class TestSuiteReports extends BaseUserComponent {
  title = 'reports-client';

  public txtContent: string
  public txtContentType: string = "docx"
  public reportType: string
  public testSuiteUids: string[] = []
  public testSuiteVersion: number
  public loading: boolean = true
  public showTestRuns: boolean = true
  public reports: any[] = []
  public currentReportUid: string
  public reportMaximized: boolean
  public report: ReportDTO
  public autoGenerate: string = ""

  moment: any = moment

  constructor(
    messageService: AppMessageService,
    store: Store,
    private route: ActivatedRoute,
    private authService: AuthService,
    private reportingService: ReportingService
  ) {
    super(store, messageService);
  }

  ngOnInit(): void {
    super.ngOnInit()
    this.subscribeToState()

    // get access token
    // this.getAccessToken()
    
    // test 
    this.txtContent = "test test";
    
    this.reportType = this.route.snapshot.queryParamMap.get('type');

    this.route.params.subscribe(params => {
      let testSuiteUidsString: string = params['testSuiteUid'] ? params['testSuiteUid'] : null
      this.testSuiteVersion = params['testSuiteVersion'] ? params['testSuiteVersion'] : 0
      this.testSuiteUids = testSuiteUidsString.split(",")
      if (this.testSuiteUids.length) {        
        this.getReports()
      }
      if (this.testSuiteUids.length && this.reportType) {      
        this.loadReport()
      }
    });
    // this.getSevenDayReportData("ehighway EDIT NAME")

    this.authService.userData$.subscribe((result) => {
      this.dispatch(new UserActions.SetCurrent({ username: result.userName })); 
    })
  }

  private getReports () {
    this.reports = []
    for (let testSuiteUid of this.testSuiteUids) {
      this.reportingService.getReports(testSuiteUid).subscribe((response) => {
        let reports = response
        for (let report of reports) {
          report.vehicleName = report.vehicleNames.join(", ")
          if (report.templateName != null) {
            report.title = report.templateName
          } else {
            if (report.reportTypeId == 270) report.title = "Simple"
            else if (report.reportTypeId == 269) report.title = "Benchmark"
            else report.titile = "Unknown"
          }        
        }
        this.reports = this.reports.concat(reports)
      })
    }
  }

  private subscribeToState() {
    // this.subscribe(UsersState.getCurrentUser, (user) => {
    //   this.user = user    
    // });
    // this.subscribe(UsersState.getAllUsers, (users) => {
    //   console.log(users.length)
    // });
    // this.subscribe(UsersState.getAccessToken, (accessToken) => {
    //   // load data when access token is acquired and schedule next access token request
    //   if (accessToken) {
    //     // this.dispatch(new UserActions.SetAllUsers()) 
    //     setTimeout(() => {
    //       this.getAccessToken()
    //     }, accessToken.expiresIn * 1000);
    //   }
    // });
  }

  // private getAccessToken () {
  //   this.authService.getAccessToken().subscribe((responseDto) => {
  //     this.dispatch(new UserActions.SetAccessToken(new AccessToken({
  //       accessToken: responseDto.access_token,
  //       refreshToken: responseDto.refresh_token,
  //       expirationTime: moment().add(responseDto.expires_in, 's').toDate(),
  //       expiresIn: responseDto.expires_in
  //     })));
  //   });
  // }

  private loadSavedReport (id) {
    this.reportingService.getReport(id).subscribe((response) => {
      this.report = response
    })
  }

  private loadReport () {
    if (!this.testSuiteUids) {
      alert("Please select test suite first")
      return
    }
    this.getCustomTestSuiteReport(this.testSuiteUids, this.reportType)
  }

  private getCustomTestSuiteReport (testSuiteUids: string[], reportTemplateUid: string) {
    this.reportingService.getCustomReport(this.txtContentType, reportTemplateUid, testSuiteUids, "testsuite", this.testSuiteVersion).subscribe((response) => {
      this.report = {
        reportContent: response,
        reportTemplateUID: reportTemplateUid,
        reportTypeId: 271,
        testRunUids: testSuiteUids
      }
    })
  }

  onSave(report: ReportSaveDto) {
    this.report.reportUID = report.reportUID
    this.getReports()
  }

  onOpenClick(reportId: string) {
    this.currentReportUid = reportId 
    this.loadSavedReport(reportId)
    //this.loadReport()
  }

  onReportMaximize() {
    this.reportMaximized = true
  }

  onReportMinimize() {
    this.reportMaximized = false
  }

  onReportTypeChange(reportType: string) {
    this.reportType = reportType
  }

  onGenerate(reportType) {
    this.loadReport()
  }

  
  // onExportFilesClick(reportId) {
  // }

  // private exportFiles () {
  //   window.open(AppConfig.ReportingAPIUrl + `/Generate/ZipWithAllFiles?testRunUidsCSV=`, "_blank");
  // }

  
}
