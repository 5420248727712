import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import * as moment from 'moment';
import { AppConfig } from 'src/config/config';
import { ReportExtensions } from '../models/reporting/ReportExtension';
import { TestRunDTO } from '../models/dto/test-run';
import { BookingDTO } from '../models/dto/booking';
import { ReportTemplateDTO } from '../models/dto/report-template';
import { Store } from '@ngxs/store';
import { GlobalActions } from '../store/global/global.actions';
import { ReportSaveDto } from '../models/dto/report-save';
import { SevenDayCollectionDTO, SevenDayMlogReportDTO, SevenDayReportDTO } from '../models/dto/seven-days';
import { ReportDTO } from '../models/dto/report';
import { TemplateSaveDto } from '../models/dto/template-save';
import { TemplateDTO } from '../models/dto/template';


//TODO: Store this information in local data
@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  constructor(
    private httpClient: HttpClient,
    private store: Store,
  ) {}

  private apiUrl = AppConfig.ReportingAPIUrl;

  httpOptions = {
    // headers: new HttpHeaders({ 'Content-Type': 'application/json'}),
    withCredentials: true
  };

  public getBenchmarkReport(extension: string, testRunUids: string[]): Observable<string> {    
    return this.httpClient
      .get<string>(this.apiUrl + `/Generate/BenchmarkingReport?extension=${extension}&testRunUidsCSV=${testRunUids.join(',')}`, this.httpOptions)
      .pipe(catchError(error => {
        console.log(error.message)
        return of('')
    }));    
  }

  public getSimpleReport(extension: string, testRunUids: string[]): Observable<string> {
    //http://sesoco3894:8698/api/Generate/SimpleListing?extension=txt&testRunUidsCSV=1422b1b4-e721-ec11-9122-0050569ef591
    return this.httpClient
      .get<string>(this.apiUrl + `/Generate/SimpleListing?extension=${extension}&testRunUidsCSV=${testRunUids.join(',')}`, this.httpOptions)
      .pipe(catchError(error => {
        console.log(error.message)
        return of('')
    }));    
  }
  
  // todo: remove dataSet it is retireved from template
  public getCustomReport(extension: string, reportTemplateUid: string, testRunUids: string[], dataSet: string = "testrun", version: number = 0): Observable<string> {
    // http://sesoco3894:8698/api/Generate/Custom?extension=docx&reportTemplateUid=17d9815d-4e31-40f4-bf50-7b198846a9ce&testRunUidsCSV=1422b1b4-e721-ec11-9122-0050569ef591
    return this.httpClient
      .get<string>(this.apiUrl + `/Generate/Custom?extension=${extension}&reportTemplateUid=${reportTemplateUid}&testRunUidsCSV=${testRunUids.join(',')}&dataSet=${dataSet}&versionsCSV=${version}`, this.httpOptions)
      .pipe(catchError(error => {
        console.log(error.message)
        return of("")
    }));  
  }

  public getTestRuns(fromDate?: Date, toDate?: Date): Observable<TestRunDTO[]> {
    // http://sesoco3894:8698/api/Filtering/GetTestRuns?fromDate=2021-10-01

    // let from = moment(fromDate).format('YYYY-MM-dd');
    // let to = moment(toDate).format('YYYY-MM-dd');
    // this.store.dispatch(new GlobalActions.IncreaseLoadingNumber(1))

    const fromDateString = fromDate ? `fromDate=${moment(fromDate).format('YYYY-MM-DD')}` : ""
    const toDateString = toDate ? `toDate=${moment(toDate).add(1,"day").format('YYYY-MM-DD')}` : ""

    return this.httpClient
      .get<TestRunDTO[]>(this.apiUrl + `/Filtering/GetTestRuns?${fromDateString}&${toDateString}`, this.httpOptions)
      .pipe(catchError(error => {
        console.log(error.message)
        return of([])
      }))
      // .pipe(() => this.store.dispatch(new GlobalActions.IncreaseLoadingNumber(-1))  
  }

  public getReportTemplates(): Observable<ReportTemplateDTO[]> {
    // http://sesoco3894:8698/api/Filtering/GetTemplates
    return this.httpClient
      .get<ReportTemplateDTO[]>(this.apiUrl + `/Filtering/GetTemplates`, this.httpOptions)
      .pipe(catchError(error => {
        console.log(error.message)
        return of([])
    }));  
  }

  public saveReport(report: ReportSaveDto): Observable<any> {
    return this.httpClient
      .post<ReportSaveDto>(this.apiUrl + `/SavedReports/Save`, report, this.httpOptions)
      .pipe(catchError(error => {
        console.log(error.message)
        return of([])
    })); 
  }

  public getBenchMarkReports(testRunUid: string): Observable<any> {
    return this.httpClient
      .get<any>(this.apiUrl + `/SavedReports/Benchmarking/?testRunUid=${testRunUid}`, this.httpOptions)
      .pipe(catchError(error => {
        console.log(error.message)
        return of([])
    })); 
  }

  public getSimpleReports(testRunUid: string): Observable<any> {
    return this.httpClient
      .get<any>(this.apiUrl + `/SavedReports/SimpleListing/?testRunUids=${testRunUid}`, this.httpOptions)
      .pipe(catchError(error => {
        console.log(error.message)
        return of([])
    })); 
  }
  
  public getCustomReports(testRunUid: string): Observable<any> {
    return this.httpClient
      .get<any>(this.apiUrl + `/SavedReports/Custom/?testRunUid=${testRunUid}`, this.httpOptions)
      .pipe(catchError(error => {
        console.log(error.message)
        return of([])
    })); 
  }

  public getReport(id: string): Observable<ReportDTO> {
    return this.httpClient
      .get<any>(this.apiUrl + `/SavedReports/GetByReportUid/?reportUid=${id}`, this.httpOptions)
      .pipe(catchError(error => {
        console.log(error.message)
        return of([])
    })); 
  }

  public getReports(testRunUid: string): Observable<any> {
    return this.httpClient
      .get<any>(this.apiUrl + `/SavedReports?testRunUid=${testRunUid}`, this.httpOptions)
      .pipe(catchError(error => {
        console.log(error.message)
        return of([])
    })); 
  }

  public getTemplates(): Observable<TemplateDTO[]> {
    return this.httpClient
      .get<TemplateDTO[]>(this.apiUrl + `/Templates/GetAll`, this.httpOptions)
      .pipe(catchError(error => {
        console.log(error.message)
        return of([])
    })); 
  }

  public getTemplate(templateUid): Observable<any> {
    return this.httpClient
      .get<any>(this.apiUrl + `/Templates/GetByUID?templateUID=${templateUid}`, this.httpOptions)
      .pipe(catchError(error => {
        console.log(error.message)
        return of([])
    })); 
  }

  public saveTemplate(template: TemplateSaveDto): Observable<any> {
    return this.httpClient
      .post<TemplateSaveDto>(this.apiUrl + `/Templates/Save`, template, this.httpOptions)
      .pipe(catchError(error => {
        console.log(error.message)
        return of([])
    }))
  }

  public getSevenDayReport(reportName: string): Observable<any> {
    // http://sesoco3894:8698/api/Generate/GetSevenDay?reportNames=ehighway%20EDIT%20NAME
    return this.httpClient
      .get<SevenDayReportDTO[]>(this.apiUrl + `/Generate/GetSevenDay?reportNames=${reportName}`, this.httpOptions)
      .pipe(catchError(error => {
        console.log(error.message)
        return of([])
    })); 
  }

  public getSevenDaySchedules(): Observable<any> {
    // http://sesoco3894:8698/api/Filtering/Get7DayReportSchedules
    return this.httpClient
      .get<SevenDayReportDTO[]>(this.apiUrl + `/Filtering/Get7DayReportSchedules`, this.httpOptions)
      .pipe(catchError(error => {
        console.log(error.message)
        return of([])
    })); 
  }

  public getSevenDayCollections(): Observable<any> {
    // http://sesoco3894:8698/api/Filtering/Get7DayReportCollections
    return this.httpClient
      .get<SevenDayReportDTO[]>(this.apiUrl + `/Filtering/Get7DayReportCollections`, this.httpOptions)
      .pipe(catchError(error => {
        console.log(error.message)
        return of([])
    })); 
  }

  public getSevenDayReportsForCollection(collectionName: string): Observable<SevenDayCollectionDTO> {
    // http://sesoco3894:8698/api/Generate/GetSevenDayCollection?collectionName=Peter
    return this.httpClient
      .get<any>(this.apiUrl + `/Generate/GetSevenDayCollection?collectionName=${collectionName}`, this.httpOptions)
      .pipe(catchError(error => {
        console.log(error.message)
        return of([])
    })); 
  }

  public getSevenDayMLogReport(reportName: string): Observable<any> {
    // https://apireports.tms.test.scania.com/Generate/GetLast7DayMLogFileSizesBySchedule?scheduleName=DavidTest
    return this.httpClient
      .get<SevenDayReportDTO[]>(this.apiUrl + `/Generate/GetLast7DayMLogFileSizesBySchedule?scheduleName=${reportName}`, this.httpOptions)
      .pipe(catchError(error => {
        console.log(error.message)
        return of([])
    })); 
  }

  public getSevenDayMLogReportsForCollection(collectionName: string): Observable<SevenDayMlogReportDTO[][]> {
    // http://sesoco3894:8698/api/Generate/GetSevenDayCollection?collectionName=Peter
    return this.httpClient
      .get<any>(this.apiUrl + `/Generate/GetLast7DayMLogFileSizesByCollection?collectionName=${collectionName}`, this.httpOptions)
      .pipe(catchError(error => {
        console.log(error.message)
        return of([])
    })); 
  }

  
  public getTRMergeFieldsStructure(): Observable<any> {
    // http://sesoco3894:8698/api/Generate/EmptyDataset
    return this.httpClient
      .get<any>(this.apiUrl + `/Generate/EmptyDataset`, this.httpOptions)
      .pipe(catchError(error => {
        console.log(error.message)
        return of([])
    })); 
  }

  public getTSMergeFieldsStructure(): Observable<any> {
    // http://sesoco3894:8698/api/Generate/EmptyDataset
    return this.httpClient
      .get<any>(this.apiUrl + `/Generate/EmptyTSReportDataset`, this.httpOptions)
      .pipe(catchError(error => {
        console.log(error.message)
        return of([])
    })); 
  }


  // public exportFiles(): Observable<any> {
  //   //https://apireports.tms.test.scania.com/Generate/ZipWithAllFiles?testRunUidsCSV=14F96EA0-ABE5-ED11-9149-0050569E7530,C0735EAC-37CE-ED11-9148-0050569E7530

  // }

// Saved benchmarking get /api/SavedReports/BenchmarkingtestRunUid (or null)
// Saved simple listing get /api/SavedReports/SimpleListingtestRunUid (or null)
// Saved custom get /api/SavedReports/CustomtestRunUid (or null)
// Report by UID get /api/SavedReports/GetByReportUidreportUid

  public parseTestRuns (testRuns: TestRunDTO[]): TestRunFilteringData {
    let data: TestRunFilteringData = {
      vehicles: [],
      bookings: [],
      testSuites: [],
      tsps: [],
      properties: []
    }
    for (let testRun of testRuns) {
      if (data.vehicles.findIndex(x => x.name == testRun.VehicleName) == -1) {
        data.vehicles.push({name: testRun.VehicleName, vin: testRun.VehicleVIN})
      }
      if (testRun.Booking && data.bookings.findIndex(x => x.ID == testRun.Booking.ID) == -1) {
        data.bookings.push(testRun.Booking)
      }
      if (data.testSuites.findIndex(x => x.uid == testRun.TSUID) == -1) {
        data.testSuites.push({
          displayName: testRun.TSName,
          uid: testRun.TSUID,
          version: testRun.TSVersion})
      }
    }
    return data
  }
}

export interface TestRunFilteringData {
  vehicles: Vehicle[]
  bookings: Booking[]
  testSuites: TestSuite[]
  tsps: TSP[]
  properties: any[]
}

export interface Vehicle {
  name: string,
  vin: string,
}

export interface TestSuite {
  displayName: string,
  uid: string
  version: number
}

export interface TSP {
  displayName: string,
  uid: string
  version: number
}

export type Booking = BookingDTO