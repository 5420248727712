import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { ScaleType } from '@swimlane/ngx-charts';
import * as moment from 'moment';
import { EmptyUid } from 'src/app/app.consts';
import { CoreUser } from 'src/app/models/core-user';
import { ReportDTO } from 'src/app/models/dto/report';
import { TemplateDTO } from 'src/app/models/dto/template';
import { TemplateSaveDto } from 'src/app/models/dto/template-save';
import { BaseUserComponent } from '../../components/base-component';
import { ScaniaDropdownOption } from '../../components/scania/scania-dropdown/scania-dropdown-option';
import { AccessToken } from '../../models/access-token';
import { ReportSaveDto } from '../../models/dto/report-save';
import { ReportExtensions } from '../../models/reporting/ReportExtension';
import { TestRunView } from '../../models/test-run';
import { AuthService } from '../../services/auth.service';
import { AppMessageService } from '../../services/message.service';
import { ReportingService } from '../../services/reporting.service';
import { GlobalState } from '../../store/global/global.state';
import { UserActions } from '../../store/users/users.actions';
import { UsersState } from '../../store/users/users.state';

declare const TXTextControl: any;

@Component({
  selector: 'tl-template',
  templateUrl: './tl-template.html',
  styleUrls: ['./tl-template.scss']
})

// Extends base user component, basic component with user 
export class TLTemplate extends BaseUserComponent {
  // title = 'reports-client';

  public txtContent: string
  public txtContentType: string = "tx"
  public reportType: string
  public loading: boolean = true
  public currentTemplate: TemplateDTO = undefined
  public currentTemplateUid: string

  constructor(
    messageService: AppMessageService,
    store: Store,
    private route: ActivatedRoute,
    private authService: AuthService,
    private reportingService: ReportingService
  ) {
    super(store, messageService);
  }

  ngOnInit(): void {

    super.ngOnInit()
    this.subscribeToState()
  
    this.route.params.subscribe(params => {
      this.currentTemplateUid = params['templateUid'] ? params['templateUid'] : null
      if (this.currentTemplateUid && this.currentTemplateUid != EmptyUid) {
        this.loadSavedTemplate(this.currentTemplateUid)
      } else if (this.currentTemplateUid && this.currentTemplateUid == EmptyUid) {
        this.currentTemplate = {}
      }
    });

  }

  private subscribeToState() {
    this.subscribe(UsersState.getCurrentUser, (user) => {
      this.user = user
    });
  }

  private loadSavedTemplate (id) {
    this.reportingService.getTemplate(id).subscribe((response) => {
      this.currentTemplate = response
    })
  }

  dropDownOnChange(event) {
    // console.log(event)
  }


  onSave(template: TemplateSaveDto) {
    this.currentTemplate.templateUid = template.templateUid
    window.open(`/templates/${template.templateUid}`,"_self")
  }

  onReportSave() {
  }

  
}
