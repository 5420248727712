import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { CoreUser } from '../models/core-user';
import { UsersState } from '../store/users/users.state';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  private user: CoreUser;

  constructor(private router: Router, private store: Store) {
    store.select(UsersState.getCurrentUser).subscribe((x) => {
      this.user = x;
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    return true
    // this.router.navigate([Routes.LOGIN]);
    // return false;
  }
}
