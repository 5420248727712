import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { ReportDTO } from 'src/app/models/dto/report';
import { ReportSaveDto } from 'src/app/models/dto/report-save';
import { BaseUserComponent } from '../../components/base-component';
import { AppMessageService } from '../../services/message.service';
import { ReportingService } from '../../services/reporting.service';
import { UserActions } from '../../store/users/users.actions';
import { AuthService } from 'src/app/services/auth.service';

declare const TXTextControl: any;

@Component({
  selector: 'testrun-reports',
  templateUrl: './testrun-reports.html',
  styleUrls: ['./testrun-reports.scss']
})

// Extends base user component, basic component with user 
export class TestRunReports extends BaseUserComponent {
  title = 'reports-client';

  public txtContent: string
  public txtContentType: string = "docx"
  public reportType: string
  public testRunUids: string[] = []
  public loading: boolean = true
  public showTestRuns: boolean = true
  public reports: any[] = []
  public currentReportUid: string
  public reportMaximized: boolean
  public report: ReportDTO
  public autoGenerate: string = ""

  moment: any = moment

  constructor(
    messageService: AppMessageService,
    store: Store,
    private route: ActivatedRoute,
    private authService: AuthService,

    private reportingService: ReportingService
  ) {
    super(store, messageService);
  }

  ngOnInit(): void {
    super.ngOnInit()

    this.subscribeToState()

    // get access token
    // this.getAccessToken()
    
    // test 
    this.txtContent = "test test";
    
    this.reportType = this.route.snapshot.queryParamMap.get('type');

    this.route.params.subscribe(params => {
      let testRunUidsString: string = params['testRunUid'] ? params['testRunUid'] : null
      this.testRunUids = testRunUidsString.split(",")
      if (this.testRunUids.length) {        
        this.getReports()
      }
      if (this.testRunUids.length && this.reportType) {      
        this.loadReport()
      }
    });
    // this.getSevenDayReportData("ehighway EDIT NAME")

    this.authService.userData$.subscribe((result) => {
      this.dispatch(new UserActions.SetCurrent({ username: result.userName })); 
    })
  }

  private getReports () {
    this.reports = []
    for (let testRunUid of this.testRunUids) {
      this.reportingService.getReports(testRunUid).subscribe((response) => {
        let reports = response
        for (let report of reports) {
          report.vehicleName = report.vehicleNames.join(", ")
          if (report.templateName != null) {
            report.title = report.templateName
          } else {
            if (report.reportTypeId == 270) report.title = "Simple"
            else if (report.reportTypeId == 269) report.title = "Benchmark"
            else report.titile = "Unknown"
          }        
        }
        this.reports = this.reports.concat(reports)
      })
    }
  }

  private subscribeToState() {
  }



  private loadSavedReport (id) {
    this.reportingService.getReport(id).subscribe((response) => {
      this.report = response
      // this.txtContent = response.reportContent
      // this.reportTemplateUID = response.reportTemplateUID
      // this.reportTypeUid = response.reportTemplateUID
      // this.currentReportUid = response.reportUID
    })
  }

  private loadReport () {
    if (!this.testRunUids) {
      alert("Please select test run first")
      return
    }
    if ((this.testRunUids.length < 2) && this.reportType == "benchmark") {
      alert("Please select at least two test runs first")
      return
    }
    if (this.reportType == "simple") this.getSimpleReport(this.testRunUids)
    else if (this.reportType == "benchmark") this.getBenchmarkReport(this.testRunUids)
    else this.getCustomReport(this.testRunUids, this.reportType)
  }

  private getCustomReport (testRunUids: string[], reportTemplateUid: string) {
    this.reportingService.getCustomReport(this.txtContentType, reportTemplateUid, testRunUids).subscribe((response) => {
      this.report = {
        reportContent: response,
        reportTemplateUID: reportTemplateUid,
        reportTypeId: 271,
        testRunUids: testRunUids
      }
    })
  }

  private getSimpleReport (testRunUids: string[]) {
    this.reportingService.getSimpleReport(this.txtContentType, testRunUids).subscribe((response) => {
      // console.log(response)
      // this.txtContent = response;
      this.report = {
        reportContent: response,
        reportTemplateUID: null,
        reportTypeId: 270,
        testRunUids: testRunUids
      }
    })
  }

  private getBenchmarkReport (testRunUids: string[]) {
    this.reportingService.getBenchmarkReport(this.txtContentType, testRunUids).subscribe((response) => {
      // console.log(response)
      this.report = {
        reportContent: response,
        reportTemplateUID: null,
        reportTypeId: 269,
        testRunUids: testRunUids
      }
    })
  }

  onSave(report: ReportSaveDto) {
    this.report.reportUID = report.reportUID
    this.getReports()
  }

  onOpenClick(reportId: string) {
    this.currentReportUid = reportId 
    this.loadSavedReport(reportId)
    //this.loadReport()
  }

  onReportMaximize() {
    this.reportMaximized = true
  }

  onReportMinimize() {
    this.reportMaximized = false
  }

  onReportTypeChange(reportType: string) {
    this.reportType = reportType
  }

  onGenerate(reportType) {
    this.loadReport()
  }

  
}
