<div style="padding-right: 10px;">
  <div style="display: flex; margin-right: 0px;">
    <h1 style="display: inline-block; margin-left: 30px;">{{reportName}}</h1>
    <p style="margin-left: 30px; padding: 0">Values are in megabytes</p>
    <div style="display: grid; flex: 2; grid-template-columns: repeat(auto-fill,minmax(350px, 1fr));">
      <div *ngFor="let chart of charts; let i = index"  style="margin: 20px; height: 200px; padding: 0px; position: relative;">
        <div  [ngStyle]="{'position': 'absolute', 'width': '20px', 'height': '20px', 'borderRadius': '10px', 'background': chart.bgColor}" ></div>
        <div [ngClass]="'chartWrapper'" [ngStyle]="{'position': 'absolute', top: '30px', left: '0', 'height': '200px'}"  attr.data-vehicle="{{chart.vehicleName}}">
          <ngx-charts-bar-vertical
            [view]="view"
            [scheme]="colorScheme"
            [schemeType]="schemeType"
            [results]="chart.data"
            [gradient]="gradient"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel"
            [xAxisLabel]="chart.vehicleName"
            [yAxisLabel]="yAxisLabel"
            [rotateXAxisTicks]="rotateXAxisTicks"
            (select)="onSelect($event)">
          </ngx-charts-bar-vertical>
        </div>
      </div>
    </div>
  </div>
</div>

