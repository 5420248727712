import { environment } from "src/environments/environment";
import { AppSecretDev, AppSecretProd, AppSercret } from "./secret";

export interface AppConfigInterface {
    TextControlWebSocketUrl: string,
    // CommonAPIUrl: string,
    ReportingAPIUrl: string,
    // AuthUrl: string,
    // AuthUrl1: string

    colaConfig: {
        disabled: boolean
        redirectUrl: string
    }
}

const AppConfigDev: AppConfigInterface = {
    TextControlWebSocketUrl: 'wss://testlinker-review.scania.com/TextControlWebSocket',
    // CommonAPIUrl: 'https://testlinker-common-dev.scania.com/api/v1',
    ReportingAPIUrl: 'https://apireports.tms.test.scania.com',
    // AuthUrl: 'https://testlinker-common-dev.scania.com/api/v1',
    // AuthUrl1: 'https://authentication.tms.scania.com/api/v1',

    colaConfig: {
        disabled: false,
        redirectUrl: 'https://testtools.testtools.devtest.gf.aws.scania.com/login'
    }
}

const AppConfigProd: AppConfigInterface = {
    TextControlWebSocketUrl: 'wss://testlinker-review.scania.com/TextControlWebSocket',
    // CommonAPIUrl: 'https://testlinker-common-dev.scania.com/api/v1',
    ReportingAPIUrl: 'https://api.reports.tms.scania.com',
    // AuthUrl: 'https://testlinker-common-dev.scania.com/api/v1',
    // AuthUrl1: 'https://authentication.tms.scania.com/api/v1',

    colaConfig: {
        disabled: false,
        redirectUrl: 'https://testtools.testtools.devtest.gf.aws.scania.com/login'
    }
}

const AppConfigLocal: AppConfigInterface = {
    TextControlWebSocketUrl: 'wss://testlinker-review.scania.com/TextControlWebSocket',
    // CommonAPIUrl: 'https://testlinker-common-dev.scania.com/api/v1',
    ReportingAPIUrl: ' https://localhost:44347',
    // AuthUrl: 'https://testlinker-common-dev.scania.com/api/v1',
    // AuthUrl1: 'https://authentication.tms.scania.com/api/v1',

    colaConfig: {
        disabled: true,
        redirectUrl: 'https://testtools.testtools.devtest.gf.aws.scania.com/login'
    }
}

export const AppConfig: AppConfigInterface = AppConfigDev