import { Component, OnDestroy } from '@angular/core';
import { _isNumberValue } from '@angular/cdk/coercion';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { ApiException } from 'src/app/core/api-exception';
import { AppMessageService } from 'src/app/services/message.service';
import { BaseStateModel } from 'src/app/store/base-state-model';
import { AuthService } from 'src/app/services/auth.service';

/**
 * Base component for handling subscriptions to Observables that implements @see OnDestroy
 * If Implementation of this component already implements @see OnDestroy
 * you must call super.ngOnDestroy()
 */
@Component({
  template: '',
})
export abstract class BaseComponent implements OnDestroy {
  private subscriptions: Subscription[] = [];

  constructor(
    protected store: Store,
    protected messageService: AppMessageService
  ) {}

  /** Creates subscription to observable, which is removed after it is completed or on component destroy */
  protected subscribeToObservable<T>(
    observable: Observable<T>,
    onSuccess?: (value: T) => void,
    onError?: (value: any) => void,
    afterCompleted?: () => void
  ) {
    if (!observable) return;
    const subscription = observable.subscribe(onSuccess, onError, () => {
      if (afterCompleted) afterCompleted();
      this.removeClosedSubscriptions();
    });
    this.subscriptions.push(subscription);
  }

  /** Creates subscription to observable, which is removed after it is completed or on component destroy */
  protected callApi<T>(
    observableApiCall: Observable<T>,
    onSuccess?: (value: T) => void,
    errorSummaryMessage?: string,
    onError?: (value: any) => void
  ) {
    this.subscribeToObservable(
      observableApiCall,
      onSuccess,
      (error: ApiException) => {
        if (errorSummaryMessage)
          this.messageService.error(errorSummaryMessage, error.message);
        if (onError) onError(error);
      }
    );
  }

  // /** Subscribes to subscription if it is not  */
  // protected subscribeIfNotYet<State extends BaseStateModel, Result>(
  //   s: Subscription | undefined,
  //   selector: (state: State) => Result,
  //   callback: (item: Result) => void
  // ): Subscription {
  //   if (s === undefined) return undefined;
  //   if (s.closed) {
  //     const idx = this.subscriptions.indexOf(s);
  //     if (idx != -1) this.subscriptions = this.subscriptions.splice(idx, 1);
  //   }

  //   return this.subscribe(selector, callback);
  // }

  /** Creates subscription to store, and return Subscription. Subscriptions are automatically cancelled on component destroy  */
  protected subscribe<State extends BaseStateModel, Result>(
    selector: (state: State) => Result,
    callback: (item: Result) => void
  ): Subscription {
    const observable = this.store.select<Result>(selector);
    return this.privateSubscribe(observable, callback);
  }

  /** Takes current value from state */
  protected subscribeOnce<State extends BaseStateModel, Result>(
    selector: (state: State) => Result,
    callback: (item: Result) => void
  ) {
    this.store.selectOnce<Result>(selector).subscribe(callback);
  }

  protected getCurrentValueFromState<State extends BaseStateModel, Result>(
    selector: (state: State) => Result
  ): Result {
    return this.store.selectSnapshot<Result>(selector);
  }

  /** Unsubscribes from subscription */
  protected unsubscribe(subscription: Subscription) {
    this.privateUnsubscribe(subscription);
  }

  /** Dispatches new action */
  protected dispatch<T>(item: T) {
    this.store.dispatch(item);
  }

  private privateSubscribe<T>(
    store: Observable<T>,
    callback: (item: T) => void
  ): Subscription {
    const subscription = store.subscribe(callback);
    this.subscriptions.push(subscription);
    return subscription;
  }

  private privateUnsubscribe(subscription: Subscription) {
    if (subscription && !subscription.closed) subscription.unsubscribe();
  }

  private removeClosedSubscriptions() {
    for (let index = 0; index < this.subscriptions.length; index++) {
      const element = this.subscriptions[index];

      if (element && element.closed) {
        this.subscriptions.splice(index, 1);
        index--;
      }
    }
  }

  ngOnDestroy(): void {
    for (const iterator of this.subscriptions) {
      this.privateUnsubscribe(iterator);
    }
    this.subscriptions = [];
  }
}
