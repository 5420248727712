import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { EmptyUid } from 'src/app/app.consts';
import { ReportDTO } from 'src/app/models/dto/report';
import { TemplateDTO } from 'src/app/models/dto/template';
import { TemplateSaveDto } from 'src/app/models/dto/template-save';
import { BaseUserComponent } from '../../components/base-component';
import { AppMessageService } from '../../services/message.service';
import { ReportingService } from '../../services/reporting.service';
import { UserActions } from '../../store/users/users.actions';
import { UsersState } from '../../store/users/users.state';
import { AuthService } from 'src/app/services/auth.service';

declare const TXTextControl: any;

@Component({
  selector: 'report-templates',
  templateUrl: './report-templates.html',
  styleUrls: ['./report-templates.scss']
})

// Extends base user component, basic component with user 
export class ReportTemplates extends BaseUserComponent {

  public txtContent: string
  public txtContentType: string = "tx"
  public loading: boolean = true
  public templates: TemplateDTO[] = []
  public currentTemplate: TemplateDTO
  public report: ReportDTO

  moment: any = moment

  constructor(
    messageService: AppMessageService,
    store: Store,
    private route: ActivatedRoute,
    private authService: AuthService,
    private reportingService: ReportingService
  ) {
    super(store, messageService);
  }

  ngOnInit(): void {
    super.ngOnInit()


    this.subscribeToState()

    // get access token
    // this.getAccessToken()

    this.authService.userData$.subscribe((result) => {
      this.dispatch(new UserActions.SetCurrent({ username: result.userName }));
    })
    // To be implemented in abstraction layer?
    // this.getTemplates()
  }

  private getTemplates() {
    this.templates = []
    this.reportingService.getTemplates().subscribe((response) => {
      this.templates = response
    })
  }

  private getTemplate(templateUid) {
    this.reportingService.getTemplate(templateUid).subscribe((response) => {
      this.currentTemplate = response
      this.report = {
        reportContent: this.currentTemplate.data,
        reportTemplateUID: this.currentTemplate.templateUid,
        reportTypeId: 271,
        testRunUids: [],
        vehicleNames: [],
        regBy: "TODO",
        regTime: new Date(),
        reportUID: this.currentTemplate.templateUid,
        version: 4
      }
    })
  }


  private subscribeToState() {
    this.subscribe(UsersState.getCurrentUser, (user) => {
      this.user = user
    });

  }


  onSave(report: TemplateSaveDto) {

  }

  onOpenClick(templateId: string) {
    this.getTemplate(templateId)
  }

  onCreateNewClick() {
    window.open(`/templates/${EmptyUid}`, '_blank')
  }

  onCreateNewMSClick() {
    window.open(`/templates-multiple-datasets/${EmptyUid}`, '_blank')
  }

}
