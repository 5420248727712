import { CoreUser } from "../models/core-user";

export function isUserEqualToUser(thisUser: CoreUser, otherUser: CoreUser) {
    if (!thisUser || !otherUser) return false;
  
    if (!thisUser.username || !otherUser.username) return false;
  
    return thisUser.username.toLowerCase() === otherUser.username.toLowerCase();
  }
  
  export function isUserEqualToUsername(thisUser: CoreUser, otherUser: string) {
    if (!thisUser || !otherUser || !thisUser.username) return false;
  
    return thisUser.username.toLowerCase() === otherUser.toLowerCase();
  }