import { AfterViewChecked, Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ScaniaDropdownOption } from './scania-dropdown-option';

declare var $: any;

@Component({
  selector: 'scania-dropdown',
  templateUrl: './scania-dropdown.html',
  styleUrls: ['./scania-dropdown.scss']
})

export class ScaniaDropdown implements OnInit, AfterViewChecked {

  @Input() label: string = ""
  @Input() options: ScaniaDropdownOption[] = []
  @Input() initialValue: string = ""
  @Input() disabled?: boolean
  @Output() onChange = new EventEmitter<ScaniaDropdownOption>();


  selectedOption: ScaniaDropdownOption = undefined;
  searchTerm: string = ""

  stringifiedOptions = '[]'
  localLabel: string = ""

  selected: string = "-"
  defaultOption: string = "---"
  
  constructor() {}

  ngAfterViewChecked(): void {
    this.setShadowCss()
  }

  ngOnInit() {    
    this.localLabel = ""
  }

  ngAfterContentChecked() {
    
  }

  ngOnChanges(changes: SimpleChanges) {
    this.stringifiedOptions = changes.options ? JSON.stringify(changes.options.currentValue) : this.stringifiedOptions
    this.localLabel = changes.label ? changes.label.currentValue : this.localLabel
    let selectedValue = changes.initialValue ? changes.initialValue.currentValue : ""
    this.selected = JSON.stringify(this.options.find(x => x.value == selectedValue))
    this.defaultOption = changes.initialValue ? changes.initialValue.currentValue : this.defaultOption

  }

  handleSearch(event) {
      this.searchTerm = event.detail
      // console.log(event)
  }

  handleChange(event) {    
    this.selectedOption = event.detail
    this.selected = JSON.stringify(this.selectedOption)
    this.onChange.emit(this.selectedOption);
    // console.log(event)
  }

  setShadowCss() {
    let zIndex = 10000;
    $("sdds-dropdown-filter").each((index, element) => {
      var sr1 = (element as any).shadowRoot
      // var sr2 = ($(sr1).find('sdds-dropdown')[0] as any).shadowRoot
      // var el = $(sr2).find("*").each((i, e) => {
      //   $(e).css('z-index', zIndex)
      //   zIndex--
      // })
      var sr3 = $(sr1).find(".hydrated").css('z-index', zIndex--)
    });
  }


}