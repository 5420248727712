import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { TargetEnvironment } from 'src/environments/environment.interfaces';
import { TmtLoggerService } from 'tmt-logger';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  // JiraIssueKey: string;
  loggedInUser: string;
  navLinks: any[];
  activeLinkIndex = 0;
  environment = environment.environment
  TargetEnvironment = TargetEnvironment
  public userName$ = this.authService.userData$.pipe(map((r) => r.userName))
  public companyName$ = this.authService.userData$.pipe(map((r) => r.companyName))
  constructor(
    // private dialog: MatDialog,
    private authService: AuthService,
    private router: Router,
    private loggerService: TmtLoggerService
  ) {

  }

  ngOnInit() {
  }

}
