import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { SysApiService } from 'src/app/services/sys-api.service';
import { AuthService } from 'src/app/services/auth.service';
import { GlobalStateModel } from './global.state.model';
import { GlobalActions } from './global.actions';

@State<GlobalStateModel>({
  name: 'global',
  defaults: {
    loadingNumber: 0
  },
})
@Injectable()
export class GlobalState {
  constructor(private sysService: SysApiService) {}

  @Action(GlobalActions.SetLoadingNumber)
  SetLoadingNumber(
    ctx: StateContext<GlobalStateModel>,
    action: GlobalActions.SetLoadingNumber
  ) {
    const state = ctx.getState();
    ctx.patchState({ loadingNumber: action.loadingNumber });
  }

  @Action(GlobalActions.IncreaseLoadingNumber)
  IncreaseLoadingNumber(
    ctx: StateContext<GlobalStateModel>,
    action: GlobalActions.IncreaseLoadingNumber
  ) {
    const state = ctx.getState();
    ctx.patchState({ loadingNumber: state.loadingNumber + action.increase });
  }

  @Selector()
  static getLoadingNumber(state: GlobalStateModel) {
    return state.loadingNumber;
  }
}
