<div class="main-container">
  <div class="table-container container">
    <form class="test-runs-form" [formGroup]="reportInputForm">
      <p-floatLabel>
        <p-calendar
        inputId="date_input"
        [maxDate]="maxDate"
        formControlName="dateRange"
        [disabled]="reportLoading$ | async"
        selectionMode="range"
        dateFormat="yy-mm-dd"></p-calendar>
        <label for="date_input">Test run execution dates</label>
      </p-floatLabel>
    </form>
    @if({
      testRuns: testRuns$ | async,
      testRunsLoading: testRunsLoading | async
    }; as source) {
      @if(!!source.testRuns && !source.testRunsLoading) {
        <p-table
        #test_run_table
        [value]="source.testRuns"
        dataKey="TestRunUid"
        [rows]="10"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[5, 10, 15]"
        [paginator]="true"
        selectionMode="single"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['TSItemNo', 'Name', 'Type', 'VehicleName', 'ExecutionTime']"
        selectionMode="single"
        (onRowSelect)="testRunSelected($event)"
    >
      <ng-template pTemplate="caption">
      <!-- <i class="scania-icon-filter"></i>Example Scania filter icon -->
        <div class="table-header" style="overflow:hidden">
          <div class="table-header-title">Test runs</div>
          <div class="spacer"></div>
          <span class="p-input-icon-left ml-auto" style="float:right">
              <input [disabled]="reportLoading$ | async" pInputText #textInput type="text" (input)="test_run_table.filterGlobal(textInput.value, 'contains')" placeholder="Search keyword" />
          </span>
      </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 4rem"></th>
          <th pSortableColumn="TSItemNo">
            <div class="column-header">
              ItemNo
              <p-columnFilter type="text" field="TSItemNo" display="menu" />
            </div>
          </th>
          <th pSortableColumn="TSName">
            <div class="column-header">
              Name (version)
              <p-columnFilter type="text" field="TSName" display="menu" />
              </div>
          </th>
          <th pSortableColumn="TSTypeId">
            <div class="column-header">
              Type
              <p-columnFilter type="text" field="TSTypeId" display="menu" />
            </div>
          </th>
          <th pSortableColumn="VehicleName">
            <div class="column-header">
              Environment
              <p-columnFilter type="text" field="VehicleName" display="menu" />
            </div>
          </th>
          <th pSortableColumn="ExecutionTime">
            <div class="column-header">
              Execution Time
              <p-columnFilter type="text" field="ExecutionTime" display="menu" />
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-testRun>
        <tr>
          <td>
            <p-tableRadioButton [value]="testRun" [disabled]="reportLoading$ | async"/>
          </td>
          <td>{{testRun.TSItemNo}}</td>
          <td>{{testRun.TSName}} ({{testRun.TSVersion}})</td>
          @if(testRun.TSTypeId === 410){
            <td>Test Specification</td>
          }
          @else {
            <td>Test Suite</td>
          }
          <td>{{testRun.VehicleName}}</td>
          <td>{{testRun.ExecutionTime}}</td>
        </tr>
      </ng-template>
    </p-table>
    } @else {
      <p-table [value]="skeletonData">
        <ng-template pTemplate="caption">
          <div class="table-header" style="overflow:hidden">
            <div class="table-header-title">Test runs</div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 4rem"></th>
            <th>ItemNo</th>
            <th>Name (version)</th>
            <th>Type</th>
            <th>Environment</th>
            <th>ExecutionTime</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr>
            <td>
              <p-tableRadioButton/>
            </td>
            @for (col of skeletonColumns; track col) {
              <td>
                <p-skeleton width="100px" height="20px"></p-skeleton>
              </td>
            }
          </tr>
        </ng-template>
      </p-table>
      }
    }
  </div>

  <div class="results-display">
    <div class="test-run-container container">
      <div class="test-run-information">
        @if(selectedTestRun$ | async; as testRun) {
          <div class="header">{{testRun.TSName}}</div>
          <div>Test run UID: {{testRun.TestRunUid}}</div>
          <div>Execution time: {{testRun.ExecutionTime}}</div>
          <div>Executed by: {{testRun.ExecutedBy}}</div>
          <div class="generate-report-container">
            <p-button size="large" [disabled]="reportLoading$ | async" (onClick)="generateReport(testRun.TestRunUid)">
              @if (reportLoading$ | async) {
                Generating report
              } @else {
                Generate report
              }
            </p-button>
            @if (reportLoading$ | async) {
              <div class="loader"></div>
            }
          </div>

        } @else {
          <div class="header">Select a test run to create a report</div>
        }
      </div>
    </div>

    @if(this.pdfUrl$ | async; as pdfUrl) {
      <div class="report-container container">
        <embed class="report-embed" [src]="pdfUrl" type="application/pdf" width="100%" height="800px">
        <a class="download-report-button" [href]="pdfUrl" target="_blank">
          <p-button label="Open report" size="large"></p-button>
        </a>
      </div>
    }
  </div>
</div>
