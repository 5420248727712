import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { CoreUser } from 'src/app/models/core-user';
import { ReportDTO } from 'src/app/models/dto/report';
import { BaseUserComponent } from '../../components/base-component';
import { ReportSaveDto } from '../../models/dto/report-save';
import { TestRunView } from '../../models/test-run';
import { AppMessageService } from '../../services/message.service';
import { ReportingService } from '../../services/reporting.service';
import { UserActions } from '../../store/users/users.actions';
import { UsersState } from '../../store/users/users.state';
import { AppConfig } from 'src/config/config';
import { AuthService } from 'src/app/services/auth.service';

declare const TXTextControl: any;

@Component({
  selector: 'tl-reports',
  templateUrl: './tl-reports.html',
  styleUrls: ['./tl-reports.scss']
})

// Extends base user component, basic component with user 
export class TLReports extends BaseUserComponent {
  title = 'reports-client';

  public txtContent: string
  public txtContentType: string = "docx"
  public reportType: string
  public testRunUid: string = ""
  public testRuns: TestRunView[]
  public loading: boolean = true
  public showTestRuns: boolean = true
  public reports: any[] = []
  public reportMaximized: boolean = false
  public currentReportUid: string
  public currentReport: ReportDTO = undefined
  public testRunUids: string[] = []

  constructor(
    messageService: AppMessageService,
    store: Store,
    private route: ActivatedRoute,
    private authService: AuthService,
    private reportingService: ReportingService
  ) {
    super(store, messageService);
  }

  ngOnInit(): void {
    super.ngOnInit()

    this.subscribeToState()

    // get access token
    // this.getAccessToken()

    // test 
    this.txtContent = "test test";

    this.route.params.subscribe(params => {
      this.currentReportUid = params['reportUid'] ? params['reportUid'] : null
      if (this.currentReportUid) {
        this.reportMaximized = true
        this.loadSavedReport(this.currentReportUid)
      }
    });

    this.route.queryParams.subscribe(params => {
      let testRunUidsString: string = params['testRunUid'] ? params['testRunUid'] : null
      if (testRunUidsString !== null)
        this.testRunUids = testRunUidsString.split(",")
    });

    this.authService.userData$.subscribe((result) => {
      this.dispatch(new UserActions.SetCurrent({ username: result.userName }));
    })
  }

  private subscribeToState() {

  }

  private getCustomReport(testRunUids: string[], reportTemplateUid: string) {
    this.reportingService.getCustomReport(this.txtContentType, reportTemplateUid, testRunUids).subscribe((response) => {
      this.currentReport = {
        reportContent: response,
        reportTemplateUID: reportTemplateUid,
        reportTypeId: 271,
        testRunUids: testRunUids
      }
    })
  }

  private getSimpleReport(testRunUids: string[]) {
    this.reportingService.getSimpleReport(this.txtContentType, testRunUids).subscribe((response) => {
      // console.log(response)
      // this.txtContent = response;
      this.currentReport = {
        reportContent: response,
        reportTemplateUID: null,
        reportTypeId: 270,
        testRunUids: testRunUids
      }
    })
  }

  private getBenchmarkReport(testRunUids: string[]) {
    this.reportingService.getBenchmarkReport(this.txtContentType, testRunUids).subscribe((response) => {
      // console.log(response)
      this.currentReport = {
        reportContent: response,
        reportTemplateUID: null,
        reportTypeId: 269,
        testRunUids: testRunUids
      }
    })
  }

  public getSevenDayReportData(reportNames: string) {
    this.reportingService.getSevenDayReport(reportNames).subscribe((response) => {
      // console.log(response)
    })
  }

  private loadReport() {
    if (!this.testRuns || this.testRuns.length == 0) {
      alert("Please select test run first")
      return
    }
    if ((this.testRuns.length < 2) && this.reportType == "benchmark") {
      alert("Please select at least two test runs first")
      return
    }
    if (this.reportType == "simple") this.getSimpleReport(this.testRuns.map(x => x.TestRunUid))
    else if (this.reportType == "benchmark") this.getBenchmarkReport(this.testRuns.map(x => x.TestRunUid))
    else this.getCustomReport(this.testRuns.map(x => x.TestRunUid), this.reportType)
  }

  private loadSavedReport(id) {
    this.reportingService.getReport(id).subscribe((response) => {
      this.currentReport = response
      this.testRuns = []
      for (let trUid of response.testRunUids)
        this.testRuns.push({
          TestRunUid: trUid,
          checked: true
        })
    })
  }

  private exportFiles(testRunUids: string[]) {
    window.open(AppConfig.ReportingAPIUrl + `/Generate/ZipWithAllFiles?testRunUidsCSV=${testRunUids.join(',')}`, "_blank");
  }

  onTestRunChange(testRunUid) {
    this.testRunUid = testRunUid
    // this.loadReport()
  }

  onSelectedTestRunsChanged(testRuns: TestRunView[]) {
    this.testRuns = testRuns
    this.getReports()
    // this.loadReport()
  }

  getReports() {
    for (let testRun of this.testRuns) {
      this.reportingService.getReports(testRun.TestRunUid).subscribe((response) => {
        testRun.reports = response
        this.formReportsArray(response)
      })
    }
  }

  formReportsArray(reports: any[]) {
    this.reports = []
    for (let report of reports) {
      report.vehicleName = report.vehicleNames.join(", ")
      if (report.templateName != null) {
        report.title = report.templateName
      } else {
        if (report.reportTypeId == 270) report.title = "Simple"
        else if (report.reportTypeId == 269) report.title = "Benchmark"
        else report.titile = "Unknown"
      }
    }
    this.reports = reports
    // this.reports = []
    // for (let testRun of this.testRuns) {
    //   for (let report of testRun.reports) {
    //     report.vehicleName = testRun.vehicleName
    //     this.reports.push(report)
    //   }      
    // }
  }

  dropDownOnChange(event) {
    // console.log(event)
  }

  onReportFormatChange(reportFormat: string) {
    this.txtContentType = reportFormat
    this.loadReport()
  }

  onReportTypeChange(reportType: string) {
    this.reportType = reportType
    this.loadReport()
  }

  onSave(report: ReportSaveDto) {
    this.currentReport.reportUID = report.reportUID
    this.getReports()
  }

  onReportSave() {

    // Benchmarking 269
    // SimpleListing 270
    // Custom 271
    const reportType = this.reportType
    const testRunUids = this.testRuns.map(x => x.TestRunUid)
    let reportTypeId = 271
    let reportTemplateUID = reportType
    if (reportType == 'benchmark') {
      reportTypeId = 269
      reportTemplateUID = null
    }
    else if (reportType == 'simple') {
      reportTypeId = 270
      reportTemplateUID = null
    }
    TXTextControl.saveDocument(TXTextControl.StreamType.WordprocessingML,
      (e) => {
        let utf8Encode = new TextEncoder();
        let encodedData = utf8Encode.encode(e.data)
        // console.log(e.data)
        let reportUID = this.currentReportUid ? this.currentReportUid : undefined
        let reportToSave: ReportSaveDto = {
          reportTypeId: reportTypeId,
          reportTemplateUID: reportTemplateUID, // null for simple and benchmark
          testRunUids: testRunUids,
          reportContent: e.data, // todo
          regBy: this.store.selectSnapshot<CoreUser>(UsersState.getCurrentUser).username, // todo
          regTime: moment().format(),
          reportUID
        }
        this.reportingService.saveReport(reportToSave).subscribe((response) => {
          // console.log(response)
        })
      }
    )
  }

  onGenerateClick() {
    this.loadReport()
  }

  onExportFilesClick(reportId) {
    if (!this.testRuns || this.testRuns.length == 0) {
      alert("Please select test run first")
      return
    }
    this.exportFiles(this.testRuns.map(x => x.TestRunUid))
  }

  onGenerate(reportType) {
    this.loadReport()
  }

  onOpenClick(reportId: string) {
    this.currentReportUid = reportId
    this.loadSavedReport(reportId)
    //this.loadReport()
  }

  onReportMaximize() {
    this.reportMaximized = true
  }

  onReportMinimize() {
    this.reportMaximized = false
  }

  toggleTestRunsView(event) {
    this.showTestRuns = !this.showTestRuns
  }

  saveDocument() {

  }

}
