import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { UsersStateModel } from './users.state.model';
import { UserActions } from './users.actions';
import { SysApiService } from 'src/app/services/sys-api.service';
import { AuthService } from 'src/app/services/auth.service';

@State<UsersStateModel>({
  name: 'users',
  defaults: {
    accessToken: undefined,
    currentUser: undefined,
    users: [],
  },
})
@Injectable()
export class UsersState {
  constructor(private sysService: SysApiService) {}

  @Action(UserActions.SetAllUsers)
  SetAllUsers(
    ctx: StateContext<UsersStateModel>,
    action: UserActions.SetAllUsers
  ) {
    const state = ctx.getState();
    //don't update state if we have already downloaded users
    if (state.users !== undefined && state.users.length > 0) return;

    // this.sysService.getCoreUsersAndRoles().subscribe((users) => {
    //   ctx.patchState({ users: users });
    // });
  }

  @Action(UserActions.SetCurrent)
  setCurrent(
    ctx: StateContext<UsersStateModel>,
    action: UserActions.SetCurrent
  ) {
    ctx.patchState({ currentUser: action.user });
  }

  @Action(UserActions.SetAccessToken)
  setAccessToken(
    ctx: StateContext<UsersStateModel>,
    action: UserActions.SetAccessToken
  ) {
    ctx.patchState({ accessToken: action.accessToken });
  }

  @Selector()
  static getCurrentUser(state: UsersStateModel) {
    return state.currentUser;
  }

  @Selector()
  static getAllUsers(state: UsersStateModel) {
    return state.users;
  }

  @Selector()
  static getAccessToken(state: UsersStateModel) {
    return state.accessToken;
  }
}
