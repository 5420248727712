import { Component, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { NgxChartsModule, ScaleType } from '@swimlane/ngx-charts';
import * as moment from 'moment';
import { BaseUserComponent } from 'src/app/components/base-component';
import { SevenDayReportDTO } from 'src/app/models/dto/seven-days';
import { AuthService } from 'src/app/services/auth.service';
import { AppMessageService } from 'src/app/services/message.service';
import { ReportingService } from 'src/app/services/reporting.service';
import { GlobalState } from 'src/app/store/global/global.state';

declare var $: any;

@Component({
  selector: 'seven-days',
  templateUrl: './seven-days.html',
  styleUrls: ['./seven-days.scss']
})
export class SevenDays extends BaseUserComponent {

  loading: boolean = false
  collections: any[] = []
  activeCollectionReports: any[] = []
  activeCollection: string = ""
  schedules: any[] = []

  constructor(
    messageService: AppMessageService,    
    store: Store,
    private reportingService: ReportingService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super(store, messageService);
  }

  ngOnInit(): void {
    
    super.ngOnInit()

    this.subscribe(GlobalState.getLoadingNumber, (loadingNumber) => {
      this.loading = loadingNumber > 0
    });

    this.getSevenDaSchedules()
    this.getSevenDayReportCollections()

    $('.runtimeInfo').remove();

  }

  public getSevenDayReportCollections () {
    this.reportingService.getSevenDayCollections().subscribe((response) => {
      this.collections = response
    })
  }

  public getSevenDayReportsForCollection (collectionName: string) {
    this.reportingService.getSevenDayReportsForCollection(collectionName).subscribe((response) => {
      this.activeCollectionReports = []
      for (let report of response.connected7DayReports) {
        if (report != null) {
          this.activeCollectionReports.push(report)
        }
      }      
    })
  }

  
  public getSevenDaSchedules () {
    this.reportingService.getSevenDaySchedules().subscribe((response) => {
      this.schedules = response
    })
  }

  public setActiveCollection (collectionName) {
    this.activeCollection = collectionName
    this.getSevenDayReportsForCollection(this.activeCollection)
  }

  public onReportClick (reportName) {
    this.router.navigate([`/seven-days/${reportName}`]);
  }

  public openCollection (collectionName) {
    this.router.navigate([`/seven-days/collections/${collectionName}`]);
  }

}