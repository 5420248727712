<sdds-theme name="scania"></sdds-theme>

<div class="main-content">
  <div style="display: flex">
    <div style="flex: 2; border: 1px solid #edeff3; border-radius: 1px; margin: 10px 10px 10px 0; padding: 0 10px">
      <div style="padding: 10px; width: 100%;">
        <tl-reports-filter-wrapper (onGenerate)="onGenerate($event)" (onReportTypeChange)="onReportTypeChange($event)"
          customType="testsuite">
        </tl-reports-filter-wrapper>
        <table *ngIf="reports.length" class="sdds-table" style="width: 100%;">
          <thead>
            <tr>
              <th>Vehicle</th>
              <th>Report type</th>
              <th>Version</th>
              <th>Registered by</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let report of reports; let i = index" (click)="onOpenClick(report.reportUID)">
              <td>{{report.vehicleName}}</td>
              <td>{{report.title}}</td>
              <td>{{report.version}}</td>
              <td>{{report.regBy}}</td>
              <td>{{moment(report.regTime).format('YYYY-MM-DD HH:mm:ss')}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="font-size: 12px;">
      </div>
    </div>
  </div>
</div>
