export namespace GlobalActions {

  export class SetLoadingNumber {
    static readonly type = '[Globa] SetLoadingNumber';
    constructor(public loadingNumber: number) {}
  }

  export class IncreaseLoadingNumber {
    static readonly type = '[Globa] IncreaseLoadingNumber';
    constructor(public increase: number) {}
  }
}
