import { AccessToken } from "src/app/models/access-token";
import { CoreUser } from "src/app/models/core-user";

export namespace UserActions {
  /** downloads all users */
  export class SetAllUsers {
    static readonly type = '[Users] SetAllUsers';
    constructor() {}
  }

  /** set current user */
  export class SetCurrent {
    static readonly type = '[Users] SetCurrentUser';
    constructor(public user: CoreUser) {}
  }

  /** set access token for api */
  export class SetAccessToken {
    static readonly type = '[Users] SetAccessToken';
    constructor(public accessToken: AccessToken) {}
  }
}
