import { Component, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { NgxChartsModule, ScaleType } from '@swimlane/ngx-charts';
import * as moment from 'moment';
import { BaseUserComponent } from 'src/app/components/base-component';
import { SevenDayCollectionDTO, SevenDayReportDTO } from 'src/app/models/dto/seven-days';
import { AuthService } from 'src/app/services/auth.service';
import { AppMessageService } from 'src/app/services/message.service';
import { ReportingService } from 'src/app/services/reporting.service';
import { GlobalState } from 'src/app/store/global/global.state';

declare var $: any;

@Component({
  selector: 'seven-days-collection',
  templateUrl: './seven-days-collection.html',
  styleUrls: ['./seven-days-collection.scss']
})
export class SevenDaysCollection extends BaseUserComponent {

  loading: boolean = false
  charts: any[] = []
  summaryChart: any[] = []
  collections: any[] = []
  collectionName: string
  currentCollection: any = {
    charts: [],
    summaryChart: [],
    reportName: ""
  }
  currentCollectionIndex: number = 0

  constructor(
    messageService: AppMessageService,    
    store: Store,
    private authService: AuthService,
    private reportingService: ReportingService,
    private route: ActivatedRoute
  ) {
    super(store, messageService);
  }

  ngOnInit(): void {
    super.ngOnInit()

    this.route.params.subscribe(params => {
      this.collectionName = params['collectionName'] ? params['collectionName'] : null
      if (this.collectionName) {        
        this.getSevenDayReportData(this.collectionName)
      }
    });

    this.subscribe(GlobalState.getLoadingNumber, (loadingNumber) => {
      this.loading = loadingNumber > 0
    });

    $('.runtimeInfo').remove();

    let that = this

    $(window).on('resize', function() {
      const timeout = setTimeout(() => {
        $('.runtimeInfo').remove();
        $('.bar').each(function() {
          var position =  $( this ).position()
          var width = $(this).closest("g")[0].getBBox().width
          var tooltip = $(this).attr("aria-label")
          var xValue = tooltip.split(" ")[0]
          var vehicleName = $(this).closest(".chartWrapper").data("vehicle")
          let value = ""
          for (let chart of that.currentCollection.charts) {
            if (chart.vehicleName == vehicleName) {
              for (let point of chart.data) {
                if (xValue == point.name) {
                  value = Math.round(point.runtime / 60).toString()
                }
              }
            }
          }
          $("body").append(`<div class="runtimeInfo" style="position: absolute; text-align: center; font-size: 16px; width: ${width}px; left: ${position.left}px; top: ${position.top - 20}px;">${value}</div>`)
        });
        clearTimeout(timeout)
      }, 1000)
    });
  }

  public getSevenDayReportData (collectionName: string) {
    
    this.reportingService.getSevenDayReportsForCollection(collectionName).subscribe((response: SevenDayCollectionDTO) => {

      this.collections = []
      for (let collection of response.connected7DayReports) {
        if (collection) {
          let newCollection = {
            charts: [],
            summaryChart: null,
            reportName: collection.reportName
          }        
          newCollection.charts = []
          newCollection.summaryChart = [
            {name: "Summary", series: []}
          ]
          // vehicle reports
          for (const data of collection.chartData) {
            const vehicleName = data.vehicleName
            const chart = newCollection.charts.filter(x => x.vehicleName == vehicleName)
            let bgColor = "#aa0000"
            if (data.logFlag == 0) bgColor = "#aa0000"
            else if (data.logFlag == 1) bgColor = "#ffdd00"
            else if (data.logFlag == 2) bgColor = "#00aa00"
            const newData = {
              name: moment(data.date).format("DD-MMM"),
              value: data.distance,
              runtime: data.runtime
            }
            if (!chart.length) {
              const newChart = {
                vehicleName: vehicleName,
                data: [newData],
                bgColor: bgColor
              }          
              newCollection.charts.push(newChart)
            } else {
              chart[0].data.push(newData)
            }
          }
          for (const chart of newCollection.charts) {
            const chartData = []
            for (let dayOffset = 0; dayOffset < 7; dayOffset++) {
              const firstDate = moment().add(-7, "day");
              const curDate = firstDate.add(dayOffset, "day").format("DD-MMM")
              const dateExist = chart.data.findIndex((x) => x.name == curDate) != -1
              if (!dateExist) {
                chartData.push({
                  name: curDate,
                  value: 0,
                  runtime: 0
                })
              } else {
                chartData.push( chart.data[ chart.data.findIndex((x) => x.name == curDate)])
              }
            }
            chart.data = chartData
            // chart.data.push({
            //   name: '26-Nov',
            //   value: 100
            // })
            chart.data.sort((a, b) => a.name > b.name ? 1 : -1)
            chart.barOffset = 100 / (chart.data.length) 
            chart.barWidth = 100 / (chart.data.length)
          }
          // summary report
          for (const data of collection.summaryChartData) {
            newCollection.summaryChart[0].series.push({
              name: moment(data.date).format("YYYY-MM-DD"),
              value: data.distance
            })
          }    
          newCollection.summaryChart[0].series.sort((a, b) => a.name > b.name ? 1 : -1)
          this.collections.push(newCollection)
        }
      }
      this.startCarousel()
    })

  }

  view: any = undefined // [700, 400];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Country';
  showYAxisLabel = false;
  yAxisLabel = 'Distance';
  rotateXAxisTicks = true;

  colorScheme =  'aqua'
  schemeType: ScaleType = ScaleType.Linear

  startCarousel () {

    this.currentCollection = this.collections[this.currentCollectionIndex]
    let that = this;
    $('.runtimeInfo').remove();
    setTimeout(function(){ window.dispatchEvent(new Event('resize')); }, 2000)
    setTimeout(function(){ that.startCarousel() }, 20000)
    this.currentCollectionIndex++
    if (this.currentCollectionIndex >= this.collections.length) this.currentCollectionIndex = 0
  }

  onSelect(event) {
    console.log(event);
  }
}