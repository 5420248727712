import { HttpLink } from 'apollo-angular/http';
import { onError } from "@apollo/client/link/error";
import { Injectable } from '@angular/core';
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, ApolloLink, InMemoryCache, from } from '@apollo/client/core';
import { v4 as uuid } from 'uuid';
import { TmtLoggerService } from 'tmt-logger';
import { combineLatest, Observable, filter, map, shareReplay } from 'rxjs';
import { AuthService } from './auth.service';
import { AppConfigService } from './app-config.service';
import { LaunchDarklyService } from './launchdarkly.service';

/**
 * Service providing apollo client
 */
@Injectable({
    providedIn: 'root'
})
export class ApolloService {

    constructor(
        private authService: AuthService,
        private ldService: LaunchDarklyService,
        private appConfigService: AppConfigService,
        private loggerService: TmtLoggerService,
        private httpLink: HttpLink,
    ) { }

    /**
     * Observable of apollo client
     */
    public apolloClients$: Observable<ApolloClients> = combineLatest({
        userData: this.authService.userData$,
        launchDarklyFlags: this.ldService.launchDarklyFlags$
    }).pipe(
        map(data => {
            // Prepare
            const config = this.appConfigService.getConfig()
            const urlAPI = data.launchDarklyFlags['staging'] ? `${config.apiStagingUrl}` : `${config.apiReleaseUrl}`
            const urlAbstractionLayer = data.launchDarklyFlags['staging'] ? `${config.abstractionLayerStagingUrl}` : `${config.abstractionLayerReleaseUrl}`

            const uriLinkAPI = this.httpLink.create({ uri: urlAPI })
            const uriLinkAbstractionLayer = this.httpLink.create({ uri: urlAbstractionLayer })

            this.loggerService.logDebug(`API url: ${urlAPI}`)
            this.loggerService.logDebug(`AbstractionLayer url: ${urlAbstractionLayer}`)

            // Headers
            const headerLink = setContext((_, { headers }) => {
                this.loggerService.logDebug('setContext');
                let correlationId = uuid();
                let requestId = uuid();
                let sessionId = data.userData.sessionID ?? 'anonymous'
                this.loggerService.logDebug("graphql sessionId: " + sessionId)

                return {
                    headers: {
                        ...headers,
                        'x-correlation-id': correlationId,
                        'x-request-id': requestId,
                        'authorization': sessionId
                    }
                }
            })

            // Error Handling
            const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
                this.loggerService.logDebug('onError');
                let correlationId = operation.getContext()['headers']['x-correlation-id'];
                let requestId = operation.getContext()['headers']['x-request-id'];
                if (graphQLErrors) {
                    graphQLErrors.forEach(({ message, locations, path }) => {
                        this.loggerService.logError(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`, correlationId)
                    });
                };

                if (networkError) {
                    this.loggerService.logError(`[Network error]: ${JSON.stringify(networkError)}`, correlationId)
                    if (networkError.message.includes('401')) {
                        this.authService.clearSession();
                    }
                }
            })

            // Start Timer
            const timeStartLink = new ApolloLink((operation, forward) => {
                this.loggerService.logDebug('forward start');
                let correlationId = operation.getContext()['headers']['x-correlation-id'];
                let requestId = operation.getContext()['headers']['x-request-id'];
                const startTime = new Date();
                this.loggerService.logInformation(`[${operation.operationName}] Sending request`, correlationId);
                operation.setContext({ start: startTime });
                this.loggerService.logDebug('forward end');
                return forward(operation);
            })

            // Stop Timer
            const timeEndLink = new ApolloLink((operation, forward) => {
                this.loggerService.logDebug('ApolloLink')
                return forward(operation).map((data) => {
                    let correlationId = operation.getContext()['headers']['x-correlation-id'];
                    let requestId = operation.getContext()['headers']['x-request-id'];
                    const duration = new Date().getTime() - operation.getContext()['start'].getTime();
                    this.loggerService.logInformation(`[${operation.operationName}] took ${duration} to complete`, correlationId);
                    return data;
                })
            })

            return {
                'reportsApiClient': new ApolloClient({
                    name: 'resultsApiClient',
                    link: from([headerLink, timeStartLink, timeEndLink, errorLink, uriLinkAPI]),
                    cache: new InMemoryCache(),
                    connectToDevTools: false
                }),
                'abstractionLayerClient': new ApolloClient({
                    name: 'abstractionLayerClient',
                    link: from([headerLink, timeStartLink, timeEndLink, errorLink, uriLinkAbstractionLayer]),
                    cache: new InMemoryCache(),
                    connectToDevTools: false
                })
            }
        }

        ),
        shareReplay(1)
    )
}

interface ApolloClients {
    [key: string]: ApolloClient<any>
}
