import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { CoreUser } from '../models/core-user';
import { SysType } from '../models/sys-type';
import { AppConfig } from 'src/config/config';


//TODO: Store this information in local data
@Injectable({
  providedIn: 'root',
})
export class SysApiService {
  constructor(
    private httpClient: HttpClient,
  ) {}

  // private apiUrl = AppConfig.CommonAPIUrl + '/DdbCore';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true
  };

  // public getAllTypes(): Observable<SysType[]> {
  //   return this.httpClient
  //     .get<SysType[]>(this.apiUrl + '/types', this.httpOptions)
  //     .pipe(shareReplay());
  //   //return of(TYPES);
  // }

  // public getCoreUsers(): Observable<CoreUser[]> {
  //   return this.httpClient
  //     .get<CoreUser[]>(this.apiUrl + '/coreUsers/', this.httpOptions)
  //     .pipe(shareReplay());
  // }

  // public getCoreUsersAndRoles(): Observable<CoreUser[]> {
  //   return this.httpClient
  //     .get<CoreUser[]>(`${this.apiUrl}/coreUsersAndRoles/`, this.httpOptions)
  //     .pipe(shareReplay());
  // }
}
