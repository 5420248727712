<sdds-theme name="scania"></sdds-theme>

<div class="main-content">
  <div style="padding: 0 20px;">
    <button class="sdds-btn sdds-btn-secondary sdds-btn-md" (click)="onCreateNewClick()"> Create new </button>
    <button class="sdds-btn sdds-btn-secondary sdds-btn-md" (click)="onCreateNewMSClick()" style="margin-left: 10px">
      Create new - multiple datasets </button>
  </div>

  <div style="display: flex">
    <div style="flex: 1; border: 1px solid #edeff3; border-radius: 1px; margin: 10px 10px 10px 0; padding: 0 10px">
      <div style="padding: 10px; width: 100%;">
        <table *ngIf="templates.length" class="sdds-table" style="width: 100%;">
          <thead>
            <tr>
              <th>Title</th>
              <th>Version</th>
              <th>Dataset</th>
              <th>Registered by</th>
              <th>Last Edited by</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let template of templates; let i = index" (click)="onOpenClick(template.templateUid)">
              <td>{{template.name}}</td>
              <td>{{template.versionNo}}</td>
              <td>{{template.dataSet}}</td>
              <td>{{template.createdBy}}</td>
              <td>{{template.lastEditedBy}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div style="flex: 2; border: 1px solid #edeff3; border-radius: 1px; margin: 10px 10px 10px 0; padding: 0 10px">
    </div>
  </div>
</div>
