<tds-header class="te-header">
    <a routerLink="/">
        <tds-header-title>Fenix Test Reports</tds-header-title>
    </a>
    <tds-header-dropdown slot="end" placement="end" no-dropdown-icon class="te-hide te-xs-show">
        <img slot="icon" src="https://www.svgrepo.com/show/384676/account-avatar-profile-user-6.svg" alt="User menu." />
        <tds-header-dropdown-list size="lg">
            <tds-header-dropdown-list-user rx header="{{userName$ | async}}"
                subheader="{{companyName$ | async}}"></tds-header-dropdown-list-user>
        </tds-header-dropdown-list>
    </tds-header-dropdown>
</tds-header>
<div class="main-table col-md-12">
    <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
</div>
