import { ToastModule } from 'primeng/toast';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxsModule } from '@ngxs/store';
import { DocumentEditorModule } from '@txtextcontrol/tx-ng-document-editor';
import { MessageService } from 'primeng/api';
import { TegelModule } from '@scania/tegel-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScaniaDropdown } from './components/scania/scania-dropdown/scania-dropdown';
import { ReportingService } from './services/reporting.service';
import { UsersState } from './store/users/users.state';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { FloatLabelModule } from 'primeng/floatlabel';
import { SkeletonModule } from 'primeng/skeleton';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TlTestrunsFilterWrapper } from './components/tl-testruns-filter-wrapper/tl-testruns-filter-wrapper';
import { TlReportsFilterWrapper } from './components/tl-reports-filter-wrapper/tl-reports-filter-wrapper';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { GlobalState } from './store/global/global.state';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SevenDaysReport } from './pages/seven-days-report/seven-days-report';
import { TLReports } from './pages/tl-reports/tl-reports';
import { TestRunReports } from './pages/testrun-reports/testrun-reports';
import { SevenDays } from './pages/seven-days/seven-days';
import { SevenDaysCollection } from './pages/seven-days-collection/seven-days-collection';
import { ReportTemplates } from './pages/report-templates/report-templates';
import { TLTemplate } from './pages/tl-template/tl-template';
import { TLTemplateMultipleDatasets } from './pages/tl-template-multiple-datasets/tl-template-multiple-datasets';
import { TestSuiteReports } from './pages/testsuite-reports/testsuite-reports';
import { SevenDaysMLogReport } from './pages/seven-days-mlog-report/seven-days-mlog-report';
import { SevenDaysMlogCollection } from './pages/seven-days-mlog-collection/seven-days-mlog-collection';
import { HeaderComponent } from './components/header/header.component';
import { AppConfigService } from './services/app-config.service';
import { ErrorHandlerService, LOGGER_CONFIG } from 'tmt-logger';
import { ReportsComponent } from 'src/app/pages/reports/reports.component';
import { ReportingGraphQLService } from 'src/app/services/graphql/reporting-graphql.service';


@NgModule({
  declarations: [
    AppComponent,
    ScaniaDropdown,
    TlTestrunsFilterWrapper,
    TlReportsFilterWrapper,
    SevenDaysReport,
    SevenDaysMLogReport,
    TLReports,
    ReportsComponent,
    TLTemplate,
    TLTemplateMultipleDatasets,
    TestRunReports,
    TestSuiteReports,
    SevenDays,
    SevenDaysCollection,
    SevenDaysMlogCollection,
    ReportTemplates,
    HeaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DocumentEditorModule,
    HttpClientModule,
    NgxsModule.forRoot([
      UsersState,
      GlobalState
    ]),
    CalendarModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ProgressSpinnerModule,
    TableModule,
    SkeletonModule,
    FloatLabelModule,
    NgxChartsModule,
    TegelModule,
    ToastModule,
  ],
  providers: [
    MessageService,
    ReportingService,
    NgxChartsModule,
    ReportingGraphQLService,
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfigService: AppConfigService) => () => appConfigService.load(),
      deps: [AppConfigService],
      multi: true
    },
    {
      provide: LOGGER_CONFIG,
      useFactory: (appConfigService: AppConfigService) => appConfigService.logConfig$,
      deps: [AppConfigService]
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA] // Add the Schema
})
export class AppModule { }
