import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { ReportTemplates } from './pages/report-templates/report-templates';
import { SevenDaysCollection } from './pages/seven-days-collection/seven-days-collection';
import { SevenDaysMlogCollection } from './pages/seven-days-mlog-collection/seven-days-mlog-collection';
import { SevenDaysMLogReport } from './pages/seven-days-mlog-report/seven-days-mlog-report';
import { SevenDaysReport } from './pages/seven-days-report/seven-days-report';
import { SevenDays } from './pages/seven-days/seven-days';
import { TestRunReports } from './pages/testrun-reports/testrun-reports';
import { TestSuiteReports } from './pages/testsuite-reports/testsuite-reports';
import { TLReports } from './pages/tl-reports/tl-reports';
import { TLTemplateMultipleDatasets } from './pages/tl-template-multiple-datasets/tl-template-multiple-datasets';
import { TLTemplate } from './pages/tl-template/tl-template';
import { AuthGuard } from './services/auth-guard.service';
import { ReportsComponent } from 'src/app/pages/reports/reports.component';

const routes: Routes = [
  { path: '', redirectTo: 'reports', pathMatch: 'full' },
  {
    path: "reports",
    canActivate: [AuthGuard],
    component: ReportsComponent
  },

  {
    path: 'reports/:reportUid',
    canActivate: [AuthGuard],
    component: TLReports
  },
  {
    path: 'testruns/:testRunUid',
    canActivate: [AuthGuard],
    component: TestRunReports
  },
  {
    path: 'testsuites/:testSuiteUid/:testSuiteVersion',
    canActivate: [AuthGuard],
    component: TestSuiteReports
  },
  {
    path: "seven-days",
    canActivate: [AuthGuard],
    component: SevenDays
  },
  {
    path: "seven-days/:reportName",
    canActivate: [AuthGuard],
    component: SevenDaysReport
  },
  {
    path: "seven-days-mlog/:reportName",
    canActivate: [AuthGuard],
    component: SevenDaysMLogReport
  },
  {
    path: "seven-days/collections/:collectionName",
    canActivate: [AuthGuard],
    component: SevenDaysCollection
  },
  {
    path: "seven-days/collections-mlog/:collectionName",
    canActivate: [AuthGuard],
    component: SevenDaysMlogCollection
  },
  {
    path: "templates",
    canActivate: [AuthGuard],
    component: ReportTemplates
  },
  {
    path: 'templates/:templateUid',
    canActivate: [AuthGuard],
    component: TLTemplate
  },
  {
    path: 'templates-multiple-datasets/:templateUid',
    canActivate: [AuthGuard],
    component: TLTemplateMultipleDatasets
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
