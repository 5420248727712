import { Component, OnDestroy, OnInit } from '@angular/core';
import { _isNumberValue } from '@angular/cdk/coercion';
import { Store } from '@ngxs/store';
import { BaseComponent } from './base-component';
import { CoreUser } from 'src/app/models/core-user';
import { UsersState } from 'src/app/store/users/users.state';
import { isUserEqualToUser } from 'src/app/utils/user.utils';
import { MessageService } from 'primeng/api';
import { AppMessageService } from 'src/app/services/message.service';

/**
 * Base component that has user callback setup that implements @see OnDestroy and @see OnInit
 * If Implementation of this component already implements @see OnDestroy or @see OnInit
 * you must call super.ngOnDestroy() or super.ngOnInit()
 */
@Component({
  template: '',
})
export abstract class BaseUserComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  /**
   * Curent user, always use it like
   * @example this.user
   * */
  public user: CoreUser;

  constructor(store: Store, messageService: AppMessageService) {
    super(store, messageService);
  }

  ngOnInit(): void {
    this.subscribe(UsersState.getCurrentUser, (user) => {
      this.privateOnUserChanged(user);
    });
  }

  private privateOnUserChanged(user: CoreUser) {
    if (isUserEqualToUser(this.user, user)) return;

    this.user = user;

    //we have logged out if this is undefined, no need to propagate this down
    if (!user) return;

    this.onUserChanged(user);
  }

  protected onUserChanged(user: CoreUser): void {}
}
