import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { UserData } from 'src/app/models/user-data.model';
import { ActivatedRoute, Router } from '@angular/router';
import { TmtLoggerService } from 'tmt-logger';
import { Config } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loggerService: TmtLoggerService

  ) { }

  private userDataSubject: BehaviorSubject<UserData> = new BehaviorSubject({
    sessionID: localStorage.getItem('sessionId'),
    userName: localStorage.getItem('userName'),
    isLoggedIn: !!localStorage.getItem('sessionId'),
    companyName: localStorage.getItem('companyName')
  } as UserData)

  public userData$ = this.userDataSubject.asObservable()

  public isLoggedIn$ = this.userData$.pipe(
    map(userData => !!userData.sessionID || !!localStorage.getItem('sessionId'))
  )

  public startedRedirect = false;

  public sessionID$ = this.userData$.pipe(
    map(userData => userData.sessionID ?? localStorage.getItem('sessionId'))
  )

  clearSession(): void {
    this.userDataSubject.next({ userName: '', sessionID: '', isLoggedIn: false, companyName: '' })

    // Clear data from local storage
    localStorage.removeItem('sessionId');
    localStorage.removeItem('userName');
    localStorage.removeItem('companyName');
  }

  updateSession(sessionId: string, username: string, company: string) {

    const existingSessionId: string = localStorage.getItem('sessionId') ?? "";
    const existingUsername: string = localStorage.getItem('userName') ?? "";
    const existingCompany: string = localStorage.getItem('companyName') ?? "";

    if (
      existingCompany !== company ||
      existingUsername !== username ||
      existingSessionId !== sessionId
    ) {
      localStorage.setItem('sessionId', sessionId);
      localStorage.setItem('userName', username);
      localStorage.setItem('companyName', company);

      this.userDataSubject.next({
        sessionID: sessionId,
        userName: username,
        companyName: company,
        isLoggedIn: true
      } as UserData)
    }
    else {
      this.removeSpecificQueryParams(['sessionId', 'username', 'company'])
    }
  }

  login(): Observable<any> {
    return this.isLoggedIn$.pipe(
      filter(isLoggedIn => !isLoggedIn),
      map(() => {
        const host = encodeURIComponent(window.location.href)
        const loginUrl = `${Config.colaConfig.redirectUrl}?origin=${host}`
        if (this.startedRedirect === false) {
          this.startedRedirect = true;
          this.loggerService.flush()
          document.location.href = loginUrl;
        }
      })
    )
  }

  removeSpecificQueryParams(paramsToRemove: string[]) {
    // Get the current query parameters
    const queryParams = { ...this.route.snapshot.queryParams };

    // Remove specific parameters
    paramsToRemove.forEach(param => delete queryParams[param]);

    // Construct a new URL without the removed parameters
    const urlTree = this.router.createUrlTree([], { queryParams });

    // Navigate to the new URL
    this.router.navigateByUrl(urlTree);
  }
}
