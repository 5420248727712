<sdds-theme name="scania"></sdds-theme>

<div style="display: flex;">
  <div style="flex: 1">
    <div style="padding: 10px">
      <h2 style="margin: 10px;">Schedules</h2>
      <table class="sdds-table" style="width: 100%; margin: 10px;">
        <thead>
          <tr>
            <th>Name</th>
            <th>Start date</th>
            <th>End date</th>
            <th>Frequency</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let schedule of schedules; let i = index">
            <td>{{schedule.name}}</td>
            <td>{{schedule.scheduleStartDate}}</td>
            <td>{{schedule.scheduleEndDate}}</td>
            <td>{{schedule.frequency}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div style="flex: 2;">
    <div style="padding: 10px">
      <h2 style="margin: 10px;">Collections</h2>
      <table class="sdds-table" style="width: 100%; margin: 10px;">
        <thead>
          <tr>
            <th>Name</th>
            <th colspan="2">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let collection of collections; let i = index" (click)="setActiveCollection(collection)">
            <td>{{collection}}</td>
            <td><a target="blank" href="/seven-days/collections/{{collection}}">Open collection</a></td>
            <td><a target="blank" href="/seven-days/collections-mlog/{{collection}}">Open Mlog collection</a></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div style="flex: 2">
    <div style="padding: 10px">
      <h2 style="margin: 10px;">Reports in collection</h2>
      <table class="sdds-table" style="width: 100%; margin: 10px;">
        <thead>
          <tr>
            <th>Name</th>
            <th colspan="2">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let report of activeCollectionReports; let i = index">
            <td>{{report.reportName}}</td>
            <td><a target="blank" href="/seven-days/{{report.reportName}}">Open report</a></td>
            <td><a target="blank" href="/seven-days-mlog/{{report.reportName}}">Open Mlog report</a></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
