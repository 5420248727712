import { Injectable } from '@angular/core';
import { Subject, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EnvironmentConfig } from 'tmt-logger';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private logConfigSubject = new Subject<EnvironmentConfig>()
  public logConfig$ = this.logConfigSubject.asObservable().pipe(shareReplay(1))
  private configuration: any

  constructor() { }

  load() {
    this.configuration = environment
    this.logConfigSubject.next(this.configuration.logConfig)
  }

  getConfig(): any {
    return this.configuration
  }
}
