<sdds-theme name="scania"></sdds-theme>

<div class="main-content">
  <div style="display: flex">
    <div *ngIf="!reportMaximized" class="test-runs-block" [class.collapsed]="!showTestRuns">
      <div *ngIf="!showTestRuns" style="position: absolute; top: 5px; right: 5px;" (click)="toggleTestRunsView($event)">
        <i class="pi pi-arrow-circle-right" style="font-size: initial;"></i>
      </div>
      <div *ngIf="showTestRuns" style="position: absolute; top: 5px; right: 5px;" (click)="toggleTestRunsView($event)">
        <i class="pi pi-arrow-circle-left" style="font-size: initial;"></i>
      </div>
      <tl-testruns-filter-wrapper *ngIf="showTestRuns" [preSelectedTestRunUids]="testRunUids"
        (onTestRunChange)="onTestRunChange($event)"
        (onSelectedTestRunsChanged)="onSelectedTestRunsChanged($event)"></tl-testruns-filter-wrapper>
    </div>
    <div style="flex: 2; border: 1px solid #edeff3; border-radius: 1px; margin: 10px 10px 10px 0; padding: 0 10px">
      <tl-reports-filter-wrapper *ngIf="!reportMaximized" (onGenerate)="onGenerate($event)"
        (onReportTypeChange)="onReportTypeChange($event)" (onExportFiles)="onExportFilesClick($event)"
        [exportFilesEnabled]="true">
      </tl-reports-filter-wrapper>
      <div *ngIf="!reportMaximized && reports.length" style="padding: 10px; width: 100%;">
        <table class="sdds-table" style="width: 100%;">
          <thead>
            <tr>
              <th>Vehicle</th>
              <th>Report type</th>
              <th>Version</th>
              <th>Registred by</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let report of reports; let i = index" (click)="onOpenClick(report.reportUID)">
              <td>{{report.vehicleName}}</td>
              <td>{{report.title}}</td>
              <td>{{report.version}}</td>
              <td>{{report.regBy}}</td>
              <td>{{report.regTime}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <div style="padding: 10px;">
          <button class="sdds-btn sdds-btn-secondary sdds-btn-sm" style="margin-right: 10px;" (click)="onGenerateClick()"> Generate <span class='sdds-btn-icon' style="color: #0f3263;"><sdds-icon name="scania-circular_arrows"></sdds-icon></span></button>
        </div> -->
    </div>
  </div>
</div>
