<sdds-dropdown-filter 
  placeholder="Select option"
  [label]="localLabel"
  (inputSearch)="handleSearch($event)"
  (selectOption)="handleChange($event)"
  [data]="stringifiedOptions"
  [defaultOption]="defaultOption"
  [disabled]="disabled"
  >
</sdds-dropdown-filter>
